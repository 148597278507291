import { useState, useEffect } from 'react';
import {
  BarChart as BaseBarChart,
  Bar,
  ResponsiveContainer,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Label,
} from 'recharts';

interface BarChartProps {
  data: [];
  yAxisLabel?: string;
  yAxisKey: string;
  xAxisKey: string;
}


const BarChart = ({data, xAxisKey, yAxisKey, yAxisLabel}: BarChartProps) => {
  const [maxY, setMaxY] = useState<number>(0)

  useEffect(() => {
    if (data.length > 0) {
      let maxValue = Math.max(...data.map((d) => d[yAxisKey]))
      maxValue = Math.ceil(maxValue / 5) * 5 + 5;
      setMaxY(maxValue);
    } else {
      setMaxY(0);  // or some other default value
    }
  }, [data])

  return (
    <ResponsiveContainer width="100%" height={300}>
      <BaseBarChart
        width={window.innerWidth}
        height={400}
        data={data}
        margin={{
          top: 20,
          right: 30,
          left: 3,
          bottom: 5,
        }}
        >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey={xAxisKey} />
        <YAxis domain={[0, maxY]} >           
          {yAxisLabel && <Label
            angle={-90}
            value={yAxisLabel}
            position="insideLeft"
            style={{ textAnchor: 'middle' }}
          /> }
          </YAxis>
        <Tooltip />
        <Bar dataKey={yAxisKey} className='fill-[var(--primary-dodger-blue)]' />
      </BaseBarChart>
    </ResponsiveContainer>
  );
};

export default BarChart;