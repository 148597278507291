import { motion } from 'framer-motion'

export const WaveDots = () => (
  <motion.div
    initial={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ duration: 0.2 }}
  >
    <div className="flex space-x-2">
      {Array.from({ length: 3 }).map((_, i) => (
        <motion.div
          key={i}
          initial={{ y: 0 }}
          animate={{ y: [0, -15, 0] }}
          transition={{ repeat: Infinity, duration: 0.65, delay: i * 0.1 }}
          className="w-3.5 h-3.5 bg-gray-700 rounded-full"
        ></motion.div>
      ))}
    </div>
  </motion.div>
);