import { styled } from '@mui/material/styles';
import BasicTooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

const Tooltip = styled(({ className, ...props }:TooltipProps) => (
  <BasicTooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: '#424242',
    color: '#fff',
    boxShadow: theme.shadows[1],
    fontSize: '11px',
    padding:'24px',
    borderRadius:'20px',
    textAlign:'center'

  },
  [`& .${tooltipClasses.arrow}`]: {
    width:'20px',
    marginTop:'10px'

  },
}));

export default Tooltip;