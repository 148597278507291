import { useState } from 'react'
import { pageTitleClass } from '../consts'
import Table from '../../table/Table'
import PieChart from '../../piechart/PieChart'
import { AssetHoldingsValuesData, AssetsHoldingsChartData, Column } from '../../../types/types'

interface Props {
  tableValuesData:AssetHoldingsValuesData[]
  assetHoldingsPieChartData:AssetsHoldingsChartData[]
  tableColumnsData:Column[]
}
const AssetsHoldings =({ tableColumnsData, tableValuesData, assetHoldingsPieChartData }:Props) => {

  const [data, setData] = useState(tableValuesData)

  return (
    <div className='w-full bg-white rounded-[15px] py-[33px] px-[40px] md:py-[77px] md:px-[97px]'>
          <h2 className={`${pageTitleClass}   min-[1228px]:mb-[69px]`}>Asset Holdings</h2>
      <div className='flex flex-col min-[1228px]:flex-row-reverse min-[1228px]:items-start min-[1228px]:justify-between'>
      <div className='w-full min-[1228px]:w-[48%] h-[200px] min-[360px]:h-[300px] min-[480px]:h-[500px] min-[1228px]:h-[600px] min-[1228px]:mt-[-100px] '>
          <PieChart data={assetHoldingsPieChartData} />
        </div>
        <div className='w-full  min-[1228px]:w-[48%]'>
          <Table
            data={data}
            columns={tableColumnsData.filter(i => i.key != 'color')}
            setData={setData}
            noBorder
          />
        </div>
       
      </div>
    </div>
  )
}

export default AssetsHoldings
