import {useState} from 'react'
import { pageTitleClass } from '../consts'
import IconImageWrapper from '../../icons/IconImageWrapper'
import transactionIcon from '../../../assets/transactions_icon.svg'
import Table from '../../table/Table'
import { Column, RecentTransactionValuesData } from '../../../types/types'

interface Props {
  tableValuesData:RecentTransactionValuesData[]
  tableColumnsData:Column[]
}
const RecentTransactions =({ tableColumnsData, tableValuesData }:Props) => {

  const [data, setData] = useState<RecentTransactionValuesData[]|[]>(tableValuesData)

  return (
    <div className='mt-[20px] md:mt-[40px] w-full bg-white rounded-[15px] py-[33px] px-[40px] md:py-[57px] md:px-[55px]'>
      <div className='flex items-center mb-[20px] md:mb-[51px]'>
        <IconImageWrapper
          src={transactionIcon}
          altText='Transaction Icon'
          wrapperClass='w-[25px] h-[25px] md:w-[43px] md:h-[43px] mr-[8px] md:mr-[20px]'
        />
        <h2 className={`${pageTitleClass}  `}>Recent Transactions</h2>
      </div>
      <Table
            data={data}
            columns={tableColumnsData.filter(i => i.key !== 'color')}
            setData={setData}
            noBorder
          />
    </div>
  )
}

export default RecentTransactions
