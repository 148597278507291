import { Template } from './template';

export const PreLogin = () => {
  const header = 'Make Money Predicting <br /> Air Quality';
  const subheader = 'Bet on changes in air quality. Web2 now, Web3 soon. Get early access to our tokens and cash prizes for participating.'
  const primaryButton = 'Sign up';
  const primaryButtonLink = '/onboarding/pick-region';
  const secondaryButton = 'Login';
  return (
    <Template 
      header={header} 
      subheader={subheader} 
      primaryButton={primaryButton} 
      primaryButtonLink={primaryButtonLink} 
      secondaryButton={secondaryButton} 
    />
  )
};
