import { Asset } from "../../types/types";
import "./styles.css";


interface ModalCardProps {
  asset: Asset;
  showAirQuality?: boolean;
}

function ModalItemCard({ asset, showAirQuality}: ModalCardProps) {

  const priceChange = 0;
  const priceBoxColor = priceChange > 0 ? 'bg-[var(--warm-orange)]' : 'bg-[var(--primary-dodger-blue)]';

  return (
    <div className={`cursor-default md:w-[17.25rem] md:h-[5rem] px-[1.31rem] py-[1.44rem] rounded-[0.625rem] md:ml-[2.56rem] mb-[0.62rem] sidebar-card-container`}>
      <div className={`flex justify-between items-center`}>
        <p className="text-base sidebar-asset-name">{asset?.name}</p>
        <div className="flex items-center">
          <div className={`sidebar-price-container flex items-center md:justify-center rounded-full md:rounded-[2.1875rem] py-[0.81rem] px-[0.5rem] w-[4.5rem] md:h-[2.52rem] ${priceBoxColor}`}>
            <p className="text-center text-base w-full md:w-13 price-text">
              {showAirQuality ? asset?.pm25 : '$' + asset?.price}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ModalItemCard;
