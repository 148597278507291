import React from 'react';
import { Pm25Hourly } from '../../types/types';
import { hour24to12, truncateString } from '../../helpers/helperFunctions';

interface Pm25InfoBoxProps {
  data: Pm25Hourly[];
  assetName: string;
  currentPrice?: number;
}

const CityCard: React.FC<Pm25InfoBoxProps> = ({ data, assetName }) => {
  if (data.length === 0) {
    return <div></div>;
  }

  let minPm25 = Infinity;
  let maxPm25 = -Infinity;
  let buyHour = -1;
  let sellHour = -1;


  for (const { avg_pm_25, hour_of_day } of data) {
    const pm25 = Number(avg_pm_25);
    const hour = Number(hour_of_day);

    if (avg_pm_25 < minPm25) {
      minPm25 = pm25;
      buyHour = hour;
    }

    if (avg_pm_25 > maxPm25) {
      maxPm25 = avg_pm_25;
      sellHour = hour;
    }
  }

  const maxGain = ((maxPm25 - minPm25) / minPm25) * 100;

  return (
    <div className="flex flex-col bg-white  py-[2.44rem] pl-[3.06rem]  rounded-[0.9375rem] md:pt-[20px] md:pb-[23px] md:pl-[1.5rem] min-[1280px]:pl-[57px] md:h-[144px]">
      <div className="mb-[0.1rem]">
        <h4 className="text-[1.875rem] md:text-[30px] font-semibold  min-[1380px]:text-[44px] ">
          {truncateString(assetName,13)}
        </h4>
      </div>
      <div className='text-gray-600'>
        <div className="text-[0.875rem]">Max Gain: <span className='font-medium text-[var(--turquoise-green)]  text-[0.875rem] mb-[0.62rem]'>{maxGain.toFixed(2)}%</span></div>
        <div className="text-[0.875rem]">High: {hour24to12(sellHour)} | Low: {hour24to12(buyHour)}</div>
        <div></div>
      </div>
    </div>
  );
};

export default CityCard;

