import { ArrowDropDown, ArrowDropUp } from '@mui/icons-material';


interface PercentProps {
  percent: string;
}

const Percent = ({percent}:PercentProps) => {
  return (
    <div 
      style={{ 
        display: 'flex', 
        alignItems: 'center', 
        justifyContent: 'flex-end', 
        color: parseFloat(percent) < 0 ? 'var(--warm-orange)' : 'var(--turquoise-green)'
      }}
    >
      {parseFloat(percent) < 0 ? <ArrowDropDown /> : <ArrowDropUp />}
      {percent}
    </div>  
  )
}

export default Percent