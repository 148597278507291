import { useEffect, useState } from 'react';
import { ScatterChart, Scatter, XAxis, YAxis, CartesianGrid, Label } from 'recharts';
import { ScatterChartDataPoint, ScatterChartData, AssetsType, ShapeProps } from '../../types/types';
import { getColorBasedOnAssetId, formatNumber } from './utils';
import { ScatterLegend } from './ScatterLegend';
import { renderCustomShape } from './ScatterShape';

interface CorrelationChartProps {
  scatterChartData: ScatterChartData;
}

export const CorrelationChart = ({scatterChartData}: CorrelationChartProps) => {
  const [data, setData] = useState<ScatterChartDataPoint[]>([]);
  const [filteredData, setFilteredData] = useState<ScatterChartDataPoint[]>([]);
  const [filteredAssets, setFilteredAssets] = useState<Set<number>>(new Set());
  const [xAxis, setXAxis] = useState('')
  const [yAxis, setYAxis] = useState('')
  const [assets, setAssets] = useState<AssetsType>({})
  const [minX, setMinX] = useState(0)
  const [minY, setMinY] = useState(0)
  const [maxX, setMaxX] = useState(0)
  const [maxY, setMaxY] = useState(0)
  const [correlation, setCorrelation] = useState(0)
  const [chartWidth, setChartWidth] = useState(window.innerWidth * 0.9);
  const [hoveredAssetId, setHoveredAssetId] = useState<number | null>(null);
  const [hoveredPoint, setHoveredPoint] = useState<ScatterChartDataPoint | null>(null);

  useEffect(() => {
    fetchCorrelations(scatterChartData)
    const handleResize = () => setChartWidth(window.innerWidth * 0.9);
    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);
  
  
  const fetchCorrelations = async (data: ScatterChartData) => {
    if (!data || !data.data) return
    setData(data.data);
    setCorrelation(data.correlation);
    setXAxis(data.xAxis);
    setYAxis(data.yAxis);
    setAssets(data.assets);
  };  

  useEffect(() => {
    setMinX(Math.min(...filteredData.map((d) => d.x)))
    setMinY(Math.min(...filteredData.map((d) => d.y)))
    setMaxX(Math.max(...filteredData.map((d) => d.x)))
    setMaxY(Math.max(...filteredData.map((d) => d.y)))
  },[filteredData])

  useEffect(() => {
    setFilteredData(data)
  }, [data])

  useEffect(() => {
    const _filteredData = data.filter(point => !filteredAssets.has(point.assetId));
    setFilteredData(_filteredData)
  }, [filteredAssets])

  return (
    <>
      <div className={`mb-4 ml-0`}>
        <span className='text-sm'>Correlation: </span>
        <span className={`${correlation >= 0 ? 'text-md text-[var(--turquoise-green)]' : 'text-[var(--warm-orange)]'}`}>{`${correlation}`}</span>
      </div>
      <ToolTip hoveredPoint={hoveredPoint} xAxis={xAxis} yAxis={yAxis} assets={assets} />
      <div className="flex flex-col items-center">
        <ScatterChart width={chartWidth} height={300} margin={{ top: 0, right: 0, bottom: 30, left: 30 }}>
          <CartesianGrid />
          <XAxis type="number" dataKey="x" domain={[minX, maxX]} tick={<CustomXTick isCurrency={true} />}>
            <Label value={xAxis} position="bottom" dy={10} />
          </XAxis>
          <YAxis type="number" dataKey="y" domain={[minY, maxY]} tick={<CustomYTick isCurrency={false} />}>
            <Label value={yAxis} position="center" angle={-90} dx={-50} dy={20} />
          </YAxis>
          {/* <Tooltip content={<CustomTooltip />} /> */}
          {/* 
          //@ts-ignore */}
          <Scatter data={filteredData} shape={(shapeProps: ShapeProps) => renderCustomShape({ shapeProps, hoveredAssetId, getColorBasedOnAssetId, setHoveredPoint })} />
        </ScatterChart>
        <ScatterLegend assets={assets} setHoveredAssetId={setHoveredAssetId} filteredAssets={filteredAssets} setFilteredAssets={setFilteredAssets} />
      </div>
    </>
  );
};


const CustomXTick = (props: any) => {
  const { x, y, payload, isCurrency } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={22} y={0} dy={16} textAnchor="middle" fill="#666">
        {formatNumber(payload.value, isCurrency)}
      </text>
    </g>
  );
};
const CustomYTick = (props: any) => {
  const { x, y, payload, isCurrency } = props;

  return (
    <g transform={`translate(${x},${y})`}>
      <text x={-23} y={0} dy={0} textAnchor="middle" fill="#666">
        {formatNumber(payload.value, isCurrency)}
      </text>
    </g>
  );
};

interface ToolTipProps {
  hoveredPoint: ScatterChartDataPoint | null;
  xAxis: string;
  yAxis: string;
  assets: AssetsType;
}

const ToolTip = ({hoveredPoint, xAxis, yAxis, assets}: ToolTipProps) => {
  return (<>
      {hoveredPoint && (
      <div className="absolute right-0 z-10 bg-white border p-2 transition duration-300 ease-in-out transform translate-y-[-5rem] translate-x-[-1rem] shadow-lg rounded-lg">
        <div className="text-sm text-gray-600 flex items-center mt-2">
          <span className="font-medium">{assets[hoveredPoint.assetId]}</span> (Cycle: {hoveredPoint.cycleId})
        </div>
        <div className="text-sm text-gray-600 flex items-center mt-2">
          <span className="font-medium">{xAxis}</span> {formatNumber(hoveredPoint.x)} <span className="font-medium"> | {yAxis}</span> {formatNumber(hoveredPoint.y)}
        </div>
      </div>
    )}
  </>)
}