import { Template } from './template';
import telegramLogo from '../../assets/telegram_logo.svg';
import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { isBaseNetwork } from '../../web3/isNetwork';

export const Community = () => {
  const [hasOpened, setHasOpened] = useState(false);
  const header = 'Win cash+token prizes. <br /> Get AI trade tips.'
  const passText = 'No thanks, I\'ll pass on opportunities to win +$10K';

  return (
    <Template header={header}>
      {!hasOpened && (
        <>
          <TelegramButton setHasOpened={setHasOpened} />
          <p className='text-sm pt-10 pl-2 text-[var(--text-color-primary)]'>{passText}</p>
        </>
      )}
      {hasOpened && ( <ContinueButton /> )}
    </Template>
  )
};

const TelegramButton = ({ setHasOpened }: { setHasOpened: (hasOpened: boolean) => void }) => {
  const telegramLink = 'https://t.me/ai_trader_bot';
  const telegramText = 'Join our Telegram Community';
  
  const openTelegram = () => {
    window.open(telegramLink, '_blank');
    setHasOpened(true);
  }
  return (
    <button 
      className='p-2 flex flex-row items-center text-[var(--text-color-primary)] rounded-lg border hover:shadow-md transition-shadow duration-300'
      onClick={openTelegram}
    >
      <img src={telegramLogo} alt="Telegram Logo" className='w-8 h-8' />
      <p className='text-xl pl-2'>{telegramText}</p>
    </button>
  )
}

const ContinueButton = () => {
  const navigate = useNavigate();
  const [isBase, setIsBase] = useState<boolean>(false);
  let continueButtonLink = isBase ? '/onboarding/tokens' : '/onboarding/pick-region';  

  useEffect(() => {
    isBaseNetwork().then((result) => {
      setIsBase(result);
    });
  }, []);

  const onContinueButtonClick = () => {
    navigate(continueButtonLink);
  }
  return (
    <button 
      className='p-2 flex flex-row items-center text-[var(--text-color-primary)] rounded-lg border hover:shadow-md transition-shadow duration-300'
      onClick={onContinueButtonClick}
    >
      <p className='text-xl pl-2'>{'CONTINUE'}</p>
    </button>
  )
}
