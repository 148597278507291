import React from 'react';
import { Tooltip, TooltipProps } from '@mui/material';

interface ConditionalTooltipProps extends Omit<TooltipProps, 'children' | 'title'> {
  children: React.ReactElement;
  title?: React.ReactNode;
}

const ConditionalTooltip: React.FC<ConditionalTooltipProps> = ({ children, title }) => {
  if (title) {
    return (
      <Tooltip title={title} enterDelay={300} enterNextDelay={300} placement="top" arrow>
        {children}
      </Tooltip>
    );
  }
  return children;
};

export default ConditionalTooltip;