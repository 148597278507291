import { useParams, useNavigate } from 'react-router-dom';
import SideBarList from '../sideBarList/SideBarList';
import { getAssets, getPm25ByHourAndDay } from '../../api/api';
import { useEffect, useState } from 'react';
import { Asset, Pm25Hourly, Pm25Daily } from '../../types/types';
import BarChart from '../BarChart/BarChart'
import { motion } from 'framer-motion';
import { RecentTrades } from '../RecentTrades/RecentTrades'
import AssetListModal from '../modal/AssetListModal';
import ErrorMessage from '../error/ErrorMessage';
import CityCard from './CityCard';
import { AssetsSidebarMinified } from '../chart/AssetMainCard';
import ChartContainer from '../chartContainer/ChartContainer';

interface AirQualityProps {
  setHeaderAssetId?: React.Dispatch<React.SetStateAction<number>>;
}


function AirQuality({ setHeaderAssetId }: AirQualityProps) {
  const { assetId } = useParams<{ assetId: string }>();
  const navigate = useNavigate();
  const [assets, setAssets] = useState<Asset[]>([]);
  const [pm25DailyData, setPm25DailyData] = useState<Pm25Daily[]>([]);
  const [pm25HourlyData, setPm25HourlyData] = useState<Pm25Hourly[]>([]);
  const [assetName, setAssetName] = useState<string>(' '); // space for skeleton / loading
  const initialAssetId = assetId ? parseInt(assetId, 10) : 1;
  const [currentAssetId, setCurrentAssetId] = useState<number>(initialAssetId);
  const [error, setError] = useState<string | null>(null);
  const [openAssetListModal, setOpenAssetListModal] = useState<boolean>(false);
  const [currentAsset, setCurrentAsset] = useState<Asset>();

  const fetchAssets = async () => {
    try {
      const assets = await getAssets();
      if (!assets) return
      setAssets(assets);
      const currentAsset = assets.find(asset => asset.id === currentAssetId)
      setCurrentAsset(currentAsset);
      if (!assetId && assets.length > 0) {
        setCurrentAssetId(assets[0].id);
      }
    } catch (error: any) {
      setError("Something went wrong! Failed to fetch assets");
    }
  };

  async function fetchPm25Data(assetId: number) {
    try {
      const chartData = await getPm25ByHourAndDay(assetId);
      if (!chartData) return
      const [byDay, byHour, assetName] = chartData
      setPm25HourlyData(byHour || []);
      setPm25DailyData(byDay || []);
      setAssetName(assetName || '')
    } catch (error: any) {
      console.error("Something went wrong! Failed to fetch price data");
    }
  }

  useEffect(() => {
    fetchPm25Data(currentAssetId);
    if (setHeaderAssetId) setHeaderAssetId(currentAssetId)
  }, [currentAssetId]);

  useEffect(() => {
    fetchAssets();
  }, []);

  const handleSelectAsset = (selectedAsset: Asset) => {
    setOpenAssetListModal(false);
    setCurrentAsset(selectedAsset)
    setCurrentAssetId(selectedAsset.id);
    navigate(`/air-quality/${selectedAsset.id}`);
  };

  if (error) {
    return <ErrorMessage message={error} />
  }

  return (
    <>
      <div className="flex flex-col md:flex-row md:flex-start h-full px-[1.5rem] md:px-[2rem] min-[800px]:px-[3rem]  max-md:mt-[20px] md:pt-[2.19rem]">
        <div className="hidden md:flex md:w-[40%] min-[800px]:w-[22%] min-[1280px]:w-[28%] md:max-w-[320px] md:overflow-hidden md:mb-[1.25rem]">
          <SideBarList assets={assets} showAirQuality={true} selectedAsset={currentAsset} handleSelectAsset={handleSelectAsset} />
        </div>
        <motion.div
          className="flex-1 flex-shrink-1 mb-5 md:mb-0 h-full order-1 md:order-2 md:ml-[5%]"
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0.7, duration: 0.5 }}
        >
          <div className="flex flex-col min-[1180px]:items-center min-[1180px]:flex-row min-[1180px]:justify-between min-[1180px]:gap-[1rem] mb-[20px] max-[1180px]:mb-0 ">
            <div className="min-[1180px]:basis-2/3  ">
              <CityCard data={pm25HourlyData} assetName={assetName} />
            </div>
            <div className="min-[1180px]:basis-1/3  max-[1180px]:mt-[20px]  ">
              <RecentTrades assetId={assetId} upperBoundSpeed={6000} lowerBoundSpeed={800}/>
            </div>
          </div>
          <ChartContainer>
              <div className="w-full md:mt-[9px]">
          <div className="w-full order-2 md:order-1 my-[1.25rem] md:hidden text-center">
            {assets && assets.length > 0 &&
                    <p className='text-sm p-4 text-gray-600'>
                      Latest pm2.5 levels
                    </p>}
          </div>
          {pm25HourlyData && (
            <BarChart data={pm25HourlyData as []} xAxisKey='hour_of_day' yAxisKey='avg_pm_25' yAxisLabel='Avg PM2.5' />
          )}
          {pm25DailyData && (
            <BarChart data={pm25DailyData as []} xAxisKey='day_of_week' yAxisKey='avg_pm_25' yAxisLabel='Avg PM2.5' />
          )}
          <p className='text-sm pt-2 text-gray-600'>
            Air quality data from past 35 days
          </p>
            <AssetsSidebarMinified showAirQuality={true} open={openAssetListModal} setOpen={setOpenAssetListModal} showArrow={true} asset={currentAsset} />
          </div>
          </ChartContainer>
        </motion.div>
      </div>
      <AssetListModal showAirQuality={true} handleSelectAsset={handleSelectAsset} assets={assets} open={openAssetListModal} setOpen={setOpenAssetListModal} />

    </>
  );
}
export default AirQuality;
