import { ReactElement } from 'react'
import { Fade, Modal } from '@mui/material'

interface Props {
  children: ReactElement
  show: boolean
  onClose: () => void
}

export const ModalLayer = ({ children, show = false, onClose }: Props) => {

  return (
    <Modal open={show} onClose={onClose}>
      <Fade in={show}>
      {children}
      </Fade>
    </Modal>
  )
}
