import { TradePresentationItem } from "../../types/types";


export const TradeCard = ({trade}: {trade: TradePresentationItem}) => {
  const action = trade.trade_type === "BUY" ? "bought" : "sold";
  return (
    <div 
      className="flex flex-col bg-white rounded-lg w-full py-2 justify-center text-center py-1 cursor-pointer hover:shadow-lg duration-300"
    >
      <div className={`flex flex-row space-x-2 text-center justify-center `}>
        <span>{getUserName(trade.user_id)}</span>
        <span>{action}</span>
        <span>{trade.asset_quantity}</span>
        <span>{trade.asset_name}</span>
      </div>
      { trade.trade_type === "SELL" && trade.absolute_pnl !== 0 && 
      <PnLCard pnlData={trade} />
      }
    </div>
  );
};

const PnLCard = ({ pnlData }: { pnlData: TradePresentationItem }) => {
  const gainLoss = pnlData.percentage_pnl >= 0 ? "gained" : "lost";
  return (
    <div className={`flex flex-col justify-center text-center ${fontColor(pnlData)}`}>
      <span className="text-sm text-center">
        {gainLoss} ${Math.abs(pnlData.absolute_pnl)} ({pnlData.percentage_pnl}%)
      </span>
    </div>
  );
};

export const getUserName = (userId: number) => {
  return userId < 10 ? "0xU..0" + userId : "0xU.." + userId;
};

const fontColor = (item: TradePresentationItem) => {
  return item.absolute_pnl > 0
    ? "text-[var(--turquoise-green)]"
    : "text-[var(--warm-orange)]";
};


export let timeoutId: NodeJS.Timeout | null = null;

export const scheduleNextUpdate = (callback: () => void, upperBound: number=3500, lowerBound: number=300) => {
  const randomDelay = Math.random() * (upperBound - lowerBound) + lowerBound;
  timeoutId = setTimeout(callback, randomDelay);
};

export const clearScheduledUpdate = () => {
  if (timeoutId) {
    clearTimeout(timeoutId);
    timeoutId = null;
  }
};