import { NetworkParams } from './networkParams';

// Adds the Base network to MetaMask
export const addNetwork = async (networkParams: NetworkParams): Promise<boolean> => {
  try {
    await window.ethereum?.request({
      method: 'wallet_addEthereumChain',
      params: [networkParams],
    });
    console.log('Base network added successfully!');
    return true;
  } catch (error) {
    console.error('Failed to add Base network:', error);
    return false;
  }
};
