
import { axiosInstance } from './axios';


const getBalance = async (): Promise<number | null> => {
  const url = `balance`;
  try {
    return axiosInstance.get(url).then((response) => {
      return response.data['balance'];
    });
  } catch (error: any) {
    console.error("Axios error", error.response?.data || error);
    throw error;
  }
}

const buyAsset = async (assetId: any, amount: number): Promise<number | null> => {
  const url = `buy`;
  try {
    return axiosInstance.post(url, { asset_id: assetId, amount_in: amount, pool_id: undefined, user_id: undefined }).then((response) => {
      return response.data;
    });
  } catch (error: any) {
    console.error("Axios error", error.response?.data || error);
    throw error;
  }
}

const sellAsset = async (assetId: any, assetQuantity: number): Promise<number | null> => {
  const url = `sell`;
  try {
    return axiosInstance.post(url, { asset_id: assetId, asset_quantity: assetQuantity, pool_id: undefined, user_id: undefined }).then((response) => {
      return response.data;
    });
  } catch (error: any) {
    console.error("Axios error", error.response?.data || error);
    throw error;
  }
}

const getUserAssets = async (assetId: any): Promise<any | null> => {
  const url = `users/assets/${assetId}`;
  try {
    return axiosInstance.get(url).then((response) => {
      return response.data["assets"];
    });
  } catch (error: any) {
    console.error("Axios error", error.response?.data || error);
    throw error;
  }
}


export {
  getBalance,
  buyAsset,
  sellAsset,
  getUserAssets
}