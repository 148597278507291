import { useEffect, useState } from "react";


export const useDebouncedResize =(ms:number) =>{
  const [dimensions, setDimensions] = useState({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const debounce = <F extends (...args: any[]) => void>(
      fn: F,
      delay: number
      ): ((...args: Parameters<F>) => void) => {
      let timer: ReturnType<typeof setTimeout> | undefined;
      return function (this: ThisParameterType<F>, ...args: Parameters<F>){
        clearTimeout(timer);
        timer = setTimeout(() => {
          timer = undefined;
          fn.apply(this, args);
        }, delay);
      };
    }

    const debouncedHandleResize = debounce(() => {
      setDimensions({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, ms);

    window.addEventListener('resize', debouncedHandleResize);

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
    };
  }, [ms]);

  return dimensions;
}
