import { ChartTimeFrame } from '../../types/types';

interface ChartTimeRange {
  label: string;
  key: ChartTimeFrame;
}

const timeRanges: ChartTimeRange[] = [
  { label: '1H', key: 'HOUR' },
  { label: '5H', key: '5HOUR' },
  { label: '1D', key: 'DAY' },
  { label: '1W', key: 'WEEK' },
];

export const TimeRangeSelector: React.FC<{
  selectedTimeRange: ChartTimeFrame;
  onTimeRangeChange: (intervalKey: ChartTimeFrame) => void;
}> = ({ selectedTimeRange, onTimeRangeChange }) => {
  return (
    <div style={{ display: 'flex', justifyContent: 'center', marginBottom: 10 }}>
      {timeRanges.map((range) => (
        <button
          key={range.label}
          onClick={() => onTimeRangeChange(range.key)}
          style={{
            padding: '5px 10px',
            margin: '0 5px',
            border: 'none',
            borderRadius: '15px',
            background: selectedTimeRange === range.key ? '#007BFF' : '#f0f0f0',
            color: selectedTimeRange === range.key ? '#fff' : '#333',
            cursor: 'pointer',
            transition: 'background 0.3s',
          }}
          onMouseOver={(e) => (e.currentTarget.style.background = '#ddd')}
          onMouseOut={(e) => (e.currentTarget.style.background = selectedTimeRange === range.key ? '#007BFF' : '#f0f0f0')}
        >
          {range.label}
        </button>
      ))}
    </div>
  );
}