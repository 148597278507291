import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { PreLogin } from './preLogin';
import { PickRegion } from './pickRegion';
import { PostBuy } from './postBuy';
import { Community } from './community';
import { Tokens } from './tokens';

export const Base = () => {
  return (
    <Routes>
      <Route path="/pre-login" element={<PreLogin />} />
      <Route path="/pick-region" element={<PickRegion />} />
      <Route path="/post-buy" element={<PostBuy />} />
      <Route path="/community" element={<Community />} />
      <Route path="/tokens" element={<Tokens />} />
    </Routes>
  );
};