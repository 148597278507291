import { useEffect, useState } from 'react';
import { getScatterChartData } from '../../api/api';
import { ScatterChartData, MultiScatterChartData } from '../../types/types';
import { CorrelationChart } from './ScatterPlot';
import { motion } from 'framer-motion'
import ErrorMessage from '../error/ErrorMessage';

export const CorrelationCharts = () => {
  const [data, setData] = useState<ScatterChartData | null>(null);
  const [data2, setData2] = useState<ScatterChartData | null>(null);
  const [data3, setData3] = useState<ScatterChartData | null>(null);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const multiData = await getScatterChartData() as MultiScatterChartData;
        const fetchedData = multiData ? multiData['airMeasure'] as ScatterChartData : null;
        const fetchedData2 = multiData ? multiData['priceDividend'] as ScatterChartData : null;
        const fetchedData3 = multiData ? multiData['priceAirMeasure'] as ScatterChartData : null;
        setData(fetchedData);
        setData2(fetchedData2);
        setData3(fetchedData3);
      } catch (error: any) {
        console.error("Axios error", error);
        setError("Something went wrong! Failed to fetch charts data.");
      }

    };

    fetchData();
  }, []);

  if (error) {
    return <ErrorMessage message={error} />
  }

  return (
    <div>
      {data3 && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          <CorrelationChart scatterChartData={data3} />
        </motion.div>
      )}
      {data && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          <CorrelationChart scatterChartData={data} />
        </motion.div>
      )}
      {data2 && (
        <motion.div initial={{ opacity: 0 }} animate={{ opacity: 1 }} transition={{ duration: 1 }}>
          <CorrelationChart scatterChartData={data2} />
        </motion.div>
      )}
    </div>
  );
}
