import { useRef, useState, useEffect } from 'react';
import Globe from 'react-globe.gl';

type PopData = {
  lat: number;
  lng: number;
  pop: number;
};

export const WorldGlobe = () => {
  const globeEl = useRef<any>();
  // Explicitly type the state as PopData[]
  const [popData, setPopData] = useState<PopData[]>([]);

  useEffect(() => {
    // load data
    fetch('/world_population.csv')
      .then(res => res.text())
      .then(csv => {
        const parsedData: PopData[] = csv.split('\n').slice(1).map(line => {
          const [lat, lng, pop] = line.split(',');
          return {
            lat: parseFloat(lat),
            lng: parseFloat(lng),
            pop: parseFloat(pop)
          };
        }).filter(item => !isNaN(item.lat) && !isNaN(item.lng) && !isNaN(item.pop));
        setPopData(parsedData);
      });
  }, []);

  useEffect(() => {
    // Auto-rotate
    if (globeEl.current) {
      globeEl.current.controls().autoRotate = true;
      globeEl.current.controls().autoRotateSpeed = 1.1;
    }
  }, []);

  const [places, setPlaces] = useState([]);

  useEffect(() => {
    // load data
    fetch('ne_110m_populated_places_simple.geojson').then(res => res.json())
      .then(({ features }) => setPlaces(features));
  }, []);


  const interpolateYlOrRd = (t: number): string => {
    const r = Math.round(255 * (1 - 0.5 * t));
    const g = Math.round(255 * (1 - t));
    const b = Math.round(255 * (1 - t));
    return `rgb(${r},${g},${b})`;
  };

  // Custom weight color function
  const weightColor = (weight: number): string => {
    const normalizedWeight = Math.min(weight / 1e7, 1);
    return interpolateYlOrRd(normalizedWeight);
  };


  return (
    <Globe
      ref={globeEl}
      globeImageUrl="//unpkg.com/three-globe/example/img/earth-night.jpg"
      bumpImageUrl="//unpkg.com/three-globe/example/img/earth-topology.png"
      backgroundImageUrl="//unpkg.com/three-globe/example/img/night-sky.png"
      hexBinPointsData={popData}
      hexBinPointWeight="pop"
      hexAltitude={d => d.sumWeight * 6e-8/3}
      hexBinResolution={4}
      hexTopColor={d => weightColor(d.sumWeight)}
      hexSideColor={d => weightColor(d.sumWeight)}
      hexBinMerge={true}
      pointLabel={d => "asdf"}
      enablePointerInteraction={false}

      labelsData={places}
      labelLat={d => (d as any).properties.latitude}
      labelLng={d => (d as any).properties.longitude}
      labelText={d => (d as any).properties.name}
      labelSize={d => Math.sqrt((d as any).properties.pop_max) * 4e-4}
      labelDotRadius={d => Math.sqrt((d as any).properties.pop_max) * 4e-4}
      labelColor={() => 'rgba(255, 165, 0, 0.75)'}
      labelResolution={2}
    />
  );
};
