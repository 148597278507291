
export const getStyleForTableContainer = (noBorder: Boolean | undefined) => {
  if (noBorder)
    return { borderRadius: '0', boxShadow: 'none', marginBottom: '40px' ,background:'#F8F8F8'}
  return { borderRadius: '1.25rem', marginBottom: '40px' }
}


export const leaderBoardTableStyle ={borderCollapse:'separate',borderSpacing:'0px 20px'}

export const leaderBoardTableBodyStyle = {
  "& .MuiTableRow-root td:first-child": {
     borderTopLeftRadius: "10px",
     borderBottomLeftRadius: "10px",
  },
  "& .MuiTableRow-root td:last-child": {
     borderTopRightRadius: "10px",
     borderBottomRightRadius: "10px",
  },
}