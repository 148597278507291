import React from 'react';
import { useEffect, useRef, useState } from 'react'
import { getBalance, sellAsset, getUserAssets } from '../../api/trade';
import { buyAsset } from '../../api/trade';
import { formatAmount } from './utils';
import AssetForm from './AssetForm';
import { BuySellSwitch } from './BuySellSwitch';
import { handleDecimalsOnValue } from "../../helpers/helperFunctions";
import { ModalContainer } from './TradeModalContainer';


type TradeModalProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  assetName: string;
  currentPrice: number;
  assetId?: string;
  postBuyCallback?: (purchasedShares: number) => void;
};

const TradeModal = ({ open, setOpen, assetName, currentPrice: currentAssetPrice, assetId, postBuyCallback }: TradeModalProps) => {
  const cancelButtonRef = useRef(null);
  const [isBuying, setIsBuying] = useState(true);
  const [currentOwnedShares, setCurrentOwnedShares] = useState(0);
  const [balance, setBalance] = useState(0);
  const [error, setError] = useState("");
  const [inputShares, setInputShares] = useState(0);
  const [successMessage, setSuccessMessage] = useState("");
  const totalPrice = getPrice(inputShares, currentAssetPrice);
  const formattedTotalPrice = formatAmount(totalPrice);
  const [isLoading, setIsLoading] = useState(false);

  const handleSwitchBuySell = () => {
    setInputShares(0);
    setError("");
    setSuccessMessage("");
    setIsBuying(prev => !prev);
  }

  const fetchData = async () => {
    try {
      const [fetchedShares, fetchedBalance] = await Promise.all([
        getUserAssets(assetId),
        getBalance()
      ]);
      setCurrentOwnedShares(fetchedShares || 0);
      setBalance(fetchedBalance || 0);
      setError(""); // reset error
    } catch (error) {
      console.error(error);
      setError("Error fetching data");
    }
  };

  const handleCloseModal = () => {
    setOpen(false);
    setInputShares(0);
    setError("");
    setSuccessMessage("");
  };


  const handleSellSharesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newShares = handleSharesChange(event);
    setError(newShares >= currentOwnedShares ? "Insufficient Shares" : "");
  };

  const handleBuySharesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newShares = handleSharesChange(event);
    setError(getPrice(newShares, currentAssetPrice) > balance ? "Insufficient Funds" : "");
  };

  const handleSharesChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    let newShares = handleDecimalsOnValue(event.target.value);
    newShares = parseFloat(newShares.replace(/^0+(?!\.)/, ''));
    if (isNaN(newShares) || newShares === "" || newShares < 0) {
      setInputShares(0);
      return;
    }
    setInputShares(newShares);
    return newShares;
  }

  const handleBuy = async (postBuyCallback?: (purchasedShares: number) => void) => {
    setIsLoading(true);
    try {
      await buyAsset(assetId, totalPrice);

      if (postBuyCallback) return postBuyCallback(inputShares);

      setSuccessMessage(`Successfully bought ${inputShares} shares of ${assetName} at ${formattedTotalPrice}`);
      setInputShares(0);
      fetchData();
    } catch (error) {
      console.error(error);
      setError("Error buying asset");
    } finally {
      setIsLoading(false);
    }
  };

  const handleSell = async () => {
    setIsLoading(true);
    try {
      await sellAsset(assetId, inputShares);
      setSuccessMessage(`Successfully sold ${inputShares} shares of ${assetName} at ${formattedTotalPrice}`);
      setInputShares(0);
      fetchData();
    } catch (error) {
      console.error(error);
      setError("Error selling asset");
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (open) {
      fetchData();
    } else {
      setError("");
      setSuccessMessage("");
    }
  }, [open, isBuying]);

  return (
    <ModalContainer open={open} setOpen={setOpen} cancelButtonRef={cancelButtonRef}>
      <BuySellSwitch
        isBuying={isBuying}
        handleSwitchBuySell={handleSwitchBuySell}
        assetName={assetName}
      />
      <AssetForm
        currentPrice={currentAssetPrice}
        cancelButtonRef={cancelButtonRef}
        shares={inputShares}
        handleSharesChange={isBuying ? handleBuySharesChange : handleSellSharesChange}
        handleAction={isBuying ? () => handleBuy(postBuyCallback) : handleSell}
        handleCloseModal={handleCloseModal}
        formattedTotalPrice={formattedTotalPrice}
        isBuying={isBuying}
        totalPrice={totalPrice}
        balance={balance}
        currentOwnedShares={currentOwnedShares}
        error={error}
        successMessage={successMessage}
        isLoading={isLoading}
        assetId={assetId}
      />
    </ModalContainer>
  );
}
export default TradeModal;

const getPrice = (shares: number, price: number) => shares * price
