import React from 'react'

interface ImageProps{
  src:string
  wrapperClass:string
  altText:string
}
const IconImageWrapper = ({src,wrapperClass,altText}:ImageProps)=>{
  return(<div className={wrapperClass}>
    <img
    src={src}
    alt={altText}
    className="w-full h-full block"
  />
  </div>
  )
}
export default IconImageWrapper