import { Template } from './template';
import { useNavigate } from 'react-router-dom';
import AssetsTable from '../assetsTable/AssetsTable';
import TradeModal from '../modal/TradeModal';
import { AssetMarketViewData } from '../../types/types';
import { useState } from 'react';
import { convertFormattedStringToNumber } from '../../helpers/helperFunctions';

export const PickRegion = () => {
  const header = 'Click a Region to Buy <br /> Air Quality Tokens';

  const navigate = useNavigate();

  const [selectedAsset, setSelectedAsset] = useState<AssetMarketViewData | null>(null);
  const [open, setOpen] = useState(false);
  const onRowClick = (row: AssetMarketViewData) => {
    setSelectedAsset(row)
    setOpen(true)
  }

  const postBuyCallback = async (purchasedShares: number) => {
    setOpen(false)
    navigate(`/onboarding/post-buy?tokenQuantity=${purchasedShares}&assetName=${selectedAsset?.data.name}`)
  }

  return (
    <Template header={header}>
      <div className='pt-10'>
        <AssetsTable forOnboarding={true} onRowClick={onRowClick} />
        {selectedAsset && (
        <TradeModal 
          open={open} 
          setOpen={setOpen} 
          assetName={selectedAsset.data.name} 
          currentPrice={convertFormattedStringToNumber(selectedAsset.data.price)} 
          assetId={selectedAsset.id.toString()}
          postBuyCallback={postBuyCallback}
        />
        )}
      </div>
    </Template>
  )
};
