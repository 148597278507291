import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useAnimation, motion, AnimatePresence, animate } from "framer-motion";
import { getTopTrades } from "../../api/api";
import { TradePresentationItem } from "../../types/types";
import { getUserName } from '../RecentTrades/supportingComponents';
import { TableRow } from "./TopTradesTableRow";


interface TopTradesHeaderProps {
  isGainers: boolean;
  setIsGainers: (isGainers: boolean) => void;
  isHovered: boolean;
}

export const TopTradesHeader = ({ isGainers, setIsGainers, isHovered }: TopTradesHeaderProps) => {
  return (
    <>
      <div className="flex items-center px-4 py-4 relative">
        {/* 24H Top Trades Label */}
        <div className="absolute left-1/2 transform -translate-x-1/2 text-xs text-gray-500">
          24H Top Trades
        </div>
        {/* Toggle Button */}
        <motion.button
          className="text-xs text-gray-500 border border-gray-300 rounded-full px-3 py-1 absolute right-4 opacity-0"
          onClick={() => setIsGainers(!isGainers)}
          initial={{ opacity: 0 }}
          animate={{ opacity: isHovered ? 1 : 0 }}
          transition={{ duration: 0.3 }}
          whileHover={{
            boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.1)",
            transition: { duration: 0.3 },
          }}
        >
          {isGainers ? "Losses" : "Gains"}
        </motion.button>
      </div>

      {/* Table Header */}
      <div className="w-full mx-auto">
        <div className="flex px-2 py-1 text-sm font-semibold text-gray-700">
          <div className="w-1/8 text-left pl-2">Rank</div>
          <div className="w-1/4 text-right pl-2 pr-2">User</div>
          <div className="w-1/3 text-right pr-4">
            {isGainers ? "$ Gains" : "$ Losses"}
          </div>
          <div className="w-1/3 text-right pr-4">
            {isGainers ? "% Gains" : "% Losses"}
          </div>
        </div>
      </div>
    </>
  );
};
