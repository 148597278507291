import React from 'react';
import { Fragment } from 'react'
import { Dialog, DialogPanel, Transition, TransitionChild } from '@headlessui/react'


type ModalContainerProps = {
  open: boolean;
  setOpen: (open: boolean) => void;
  cancelButtonRef: React.RefObject<HTMLButtonElement>;
  children: React.ReactNode;
};

export const ModalContainer = ({ open, setOpen, cancelButtonRef, children }: ModalContainerProps) => (
  <Transition show={open} as={Fragment}>
    <Dialog as="div" className="relative z-10" initialFocus={cancelButtonRef} onClose={setOpen}>
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto" aria-hidden="true" />
      <ModalOverlay />
      <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
        <div className="flex min-h-full items-end justify-center p-4 text-center md:items-center md:p-0">
          <ModalTransition open={open}>
            <DialogPanel className="relative transform overflow-hidden rounded-lg bg-white shadow-xl transition-all md:my-8 w-[390px] md:w-[700px]">
            <div className="bg-white px-[25px] md:px-[69px] py-[33px] md:py-[49px] md:p-8 pb-[31px] md:pb-[50px]">
              <div className="md:flex md:items-start">
                <div className="mt-3 text-left md:w-full md:mt-0">
                  {children}
                </div>
              </div>
            </div>
            </DialogPanel>
          </ModalTransition>
        </div>
      </div>
    </Dialog>
  </Transition>
);

const ModalTransition = ({ children, open }: { children: React.ReactNode, open: boolean }) => (
  <Transition
    as={Fragment}
    show={open}
    enter="ease-out duration-100"
    enterFrom="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
    enterTo="opacity-100 translate-y-0 md:scale-100"
    leave="ease-in duration-100"
    leaveFrom="opacity-100 translate-y-0 md:scale-100"
    leaveTo="opacity-0 translate-y-4 md:translate-y-0 md:scale-95"
  >
    {children}
  </Transition>
);

const ModalOverlay = () => (
  <TransitionChild
    as={Fragment}
    enter="ease-out duration-300"
    enterFrom="opacity-0"
    enterTo="opacity-100"
    leave="ease-in duration-200"
    leaveFrom="opacity-100"
    leaveTo="opacity-0"
  >
    <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
  </TransitionChild>
);