import axios, { AxiosInstance } from 'axios';
import { useEffect } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { BASE_URL } from './constants';

export const axiosInstance: AxiosInstance = axios.create({
  baseURL: BASE_URL,
  timeout: 45000,
});

axiosInstance.interceptors.request.use(
  config => {
    // Add the Authorization header with the JWT token from sessionStorage
    config.headers['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
    config.headers['Content-Type'] = 'application/json';
    return config;
  },
  error => Promise.reject(error)
);

export const useAxiosConfig = () => {
  const { getIdTokenClaims, isAuthenticated } = useAuth0();

  useEffect(() => {
    const setGlobalAxiosSettings = async () => {
      try {
        if (isAuthenticated) {
          const tokenClaims = await getIdTokenClaims();
          const id_token:any = tokenClaims?.__raw;
          // Store the token in sessionStorage instead of localStorage
          sessionStorage.setItem('token', id_token);
        }
      } catch (error) {
        console.log('An error occurred:', error);
        console.error(error);
      }
    };

    setGlobalAxiosSettings();
  }, [getIdTokenClaims, isAuthenticated]);
};
