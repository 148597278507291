import { useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion'

interface TemplateProps {
  header: string;
  subheader?: string;
  primaryButton?: string;
  primaryButtonLink?: string;
  onPrimaryButtonClick?: () => void;
  primaryButtonDisabled?: boolean;
  secondaryButton?: string;
  errorMessage?: string;
  onSignUp?: () => void;
  onLogin?: () => void;
  children?: React.ReactNode;
}

export const Template = ({ 
  header, 
  subheader, 
  primaryButton, 
  primaryButtonLink,
  errorMessage,
  onPrimaryButtonClick,
  primaryButtonDisabled,
  secondaryButton, 
  onSignUp, 
  onLogin, 
  children 
}: TemplateProps) => {
  const navigate = useNavigate();

  const handlePrimaryButtonClick = () => {
    if (primaryButtonLink) {
      navigate(primaryButtonLink);
    } else if (onPrimaryButtonClick) {
      onPrimaryButtonClick();
    } else if (onSignUp) {
      onSignUp();
    }
  };

  return (
      <div className={onboardingStyles.container}>
        <motion.div
          className="flex flex-col justify-center items-center"
          initial={{ opacity: 0, scale: .99 }}
          animate={{ opacity: 1, scale: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0, duration: 0.4 }}
        >
          <h2 className={onboardingStyles.heading}>{<span dangerouslySetInnerHTML={{ __html: header }} />}</h2>
          {subheader && <p className={onboardingStyles.paragraph}>{subheader}</p> || <div className="pb-8" />}
          {primaryButton && (
            <button disabled={primaryButtonDisabled} onClick={handlePrimaryButtonClick} className={onboardingStyles.signupButton}>
              {primaryButton}
            </button>
          )}
          {secondaryButton && (
            <button onClick={onLogin} className={onboardingStyles.loginButton}>
              {secondaryButton}
            </button>
          )}
        </motion.div>
        {errorMessage && <p>{errorMessage}</p>}
        <motion.div
          className=""
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          exit={{ opacity: 0 }}
          transition={{ delay: 0, duration: 0.93 }}
        >
          {children}
        </motion.div>
      </div>
  );
};

const onboardingStyles = {
  container: 'rounded-lg w-full py-6 md:py-44 px-9 md:px-18 min-[1080px]:px-25 min-[1510px]:px-80 bg-white flex flex-col justify-center items-center',
  heading: 'text-3xl min-[380px]:text-3xl xl:mx-20 lg:mx-18 md:mx-10 mx-4 md:text-4xl min-[786px]:text-5xl min-[1020px]:text-6xl text-[var(--text-color-primary)] font-black text-center',
  paragraph: 'text-sm min-[380px]:text-lg md:text-2xl text-[var(--text-color-primary)] font-normal my-9 md:my-18 w-[90%] min-[1080px]:w-[60%] text-center',
  signupButton: 'bg-[var(--primary-dodger-blue)] w-[118px] md:w-[263px] py-[12px] min-[380px]:py-[16px] md:py-[13px] flex justify-center text-[white] text-[12px] min-[380px]:text-[15px] md:text-[20px] font-white font-bold rounded-[10px] md:rounded-[17px] cursor-pointer',
  loginButton: 'w-[118px] md:w-[263px] py-[12px] min-[380px]py-[16px] md:py-[13px] flex justify-center cursor-pointer text-[var(--primary-dodger-blue)] text-[12px] min-[380px]:text-[15px] md:text-[20px] font-white md:font-bold cursor-pointer',
};