

export const formatAmount = (amount: number) => {
  if (isNaN(amount)) return '$0.00'
  if (amount < 0) return '$0.00'
  return '$' + new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(amount)
}

export const formatNumberTo2Decimal = (_number: number) => {
  if (isNaN(_number)) return '0'
  if (_number < 0) return '0'
  return new Intl.NumberFormat('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(_number)
}
