import { Template } from './template';
import { useLocation } from 'react-router-dom';

export const PostBuy = () => {
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);

  const tokenQuantity = searchParams.get('tokenQuantity');
  const assetName = searchParams.get('assetName');

  const header = `Congratulations on Buying ${tokenQuantity} ${assetName} Air Quality Tokens.`;
  const primaryButton = 'Continue'
  const primaryButtonLink = '/onboarding/community'

  return (
    <Template 
      header={header} 
      primaryButton={primaryButton} 
      primaryButtonLink={primaryButtonLink} 
    />
  )
};
