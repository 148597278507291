import { NetworkParams, BASE_NETWORK_PARAMS } from './networkParams';

export const isBaseNetwork = async (): Promise<boolean> => {
  return await isNetwork(BASE_NETWORK_PARAMS);
};


export const isNetwork = async (network: NetworkParams): Promise<boolean> => {
  if (window.ethereum) {
    try {
      const chainIdHex: string = await window.ethereum.request({ method: 'eth_chainId' });
      const chainId = parseInt(chainIdHex, 16);
      return chainId === parseInt(network.chainId, 16);
    } catch (error) {
      console.error('Error fetching chain ID:', error);
      return false;
    }
  } else {
    console.error('Ethereum provider not found');
    return false;
  }
};
