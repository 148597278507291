import React, { useEffect, useState, useRef } from "react";
import { motion, AnimatePresence, useMotionValue, animate } from "framer-motion";
import { getTopTrades } from "../../api/api";
import { TradePresentationItem } from "../../types/types";
import { TableRow } from "./TopTradesTableRow";
import { TopTradesHeader } from "./TopTradesHeader";

export const TopTrades: React.FC<{ assetId: string }> = ({ assetId }) => {
  const [gainers, setGainers] = useState<TradePresentationItem[]>([]);
  const [losses, setLosses] = useState<TradePresentationItem[]>([]);
  const [isGainers, setIsGainers] = useState(true);
  const [isHovered, setIsHovered] = useState(false);
  const [displayData, setDisplayData] = useState<TradePresentationItem[]>([]);
  const isMounted = useRef(false);

  // Use useMotionValue for y position
  const yMotionValue = useMotionValue(0);

  // Ref and state to measure content height
  const scrollContainerRef = useRef<HTMLDivElement>(null);
  const [contentHeight, setContentHeight] = useState(0);

  // Track component mount status
  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  // Fetch data when assetId changes
  useEffect(() => {
    const fetchTransactionData = async () => {
      try {
        const fetchedData = await getTopTrades(assetId);
        const gainersWithKeys = fetchedData.gainers.map((item, index) => ({
          ...item,
          uniqueKey: `${item.user_id}-${item.id}-${index}`,
        }));
        const lossesWithKeys = fetchedData.losers.map((item, index) => ({
          ...item,
          uniqueKey: `${item.user_id}-${item.id}-${index}`,
        }));
        setGainers(gainersWithKeys);
        setLosses(lossesWithKeys);
      } catch (error) {
        console.error("Error fetching transaction data:", error);
      }
    };

    fetchTransactionData();
  }, [assetId]);

  // Update displayData when isGainers changes or data is fetched
  useEffect(() => {
    setDisplayData(isGainers ? gainers : losses);
  }, [isGainers, gainers, losses]);

  // Measure content height when displayData changes
  useEffect(() => {
    if (scrollContainerRef.current) {
      setContentHeight(scrollContainerRef.current.scrollHeight);
    }
  }, [displayData]);

  // **Separate useEffect to reset yMotionValue when displayData changes**
  useEffect(() => {
    // Reset yMotionValue to 0 when displayData changes to start from the top
    yMotionValue.set(0);
  }, [displayData, yMotionValue]);

  // Initialize and manage scrolling animation
  useEffect(() => {
    if (!isMounted.current || displayData.length === 0 || isHovered) return;

    if (contentHeight === 0) return;

    const targetYValue = -contentHeight / 2; // Scrolling half the content height due to duplication

    const totalDistance = Math.abs(targetYValue);
    const totalDuration = 100; // Total duration for full scroll in seconds

    // Start the animation
    const controls = animate(yMotionValue, targetYValue, {
      duration: totalDuration,
      ease: "linear",
      repeat: Infinity,
    });

    return () => {
      controls.stop();
    };
  }, [displayData, contentHeight, yMotionValue, isHovered]);

  // Handle mouse entering the component
  const handleMouseEnter = () => {
    setIsHovered(true);
    // Pause the animation by stopping it
    yMotionValue.stop();
  };

  // Handle mouse leaving the component
  const handleMouseLeave = () => {
    setIsHovered(false);

    if (contentHeight === 0) return;

    const currentY = yMotionValue.get();
    const targetYValue = -contentHeight / 2;

    const remainingDistance = Math.abs(targetYValue - currentY);
    const totalDistance = Math.abs(targetYValue);

    const totalDuration = 100; // Total duration for full scroll in seconds

    // Calculate remaining duration proportionally
    const remainingDuration = (remainingDistance / totalDistance) * totalDuration;

    // Resume the animation from currentY to targetYValue
    animate(yMotionValue, targetYValue, {
      duration: remainingDuration,
      ease: "linear",
      repeat: Infinity,
    });
  };

  // Variants for row animations
  const rowVariants = {
    hidden: { opacity: 0, y: 10 },
    visible: (i: number) => ({
      opacity: 1,
      y: 0,
      transition: {
        delay: i * 0.03, // 30ms between each row
        duration: 0.3,    // 300ms fade-in
      },
    }),
    exit: { opacity: 0, y: -10, transition: { duration: 0.3 } },
  };

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{ duration: 0.5 }}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
      className="h-74 rounded-lg border border-gray-200 mb-2 relative overflow-hidden"
    >
      {/* Header with Centered Label and Toggle Button */}
      <TopTradesHeader 
        isGainers={isGainers} 
        setIsGainers={setIsGainers} 
        isHovered={isHovered} 
      />

      {/* Scrollable Trade Rows with Fade Transition and Infinite Scroll */}
      <div className="overflow-hidden h-64 w-full mx-auto relative">
        <motion.div
          style={{ y: yMotionValue }}
          ref={scrollContainerRef}
          className="flex flex-col"
        >
          {/* AnimatePresence wraps both original and duplicated data */}
          <AnimatePresence mode="wait">
            {/* Original Data */}
            {displayData.map((item, index) => (
              <motion.div
                key={`${item.uniqueKey}-${isGainers}`}
                custom={index}
                variants={rowVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <TableRow 
                  item={item} 
                  index={index + 1} 
                  isGainers={isGainers} 
                />
              </motion.div>
            ))}
            {/* Duplicated Data for Infinite Scrolling */}
            {displayData.map((item, index) => (
              <motion.div
                key={`${item.uniqueKey}-${isGainers}-duplicate`} // Ensure unique keys
                custom={index}
                variants={rowVariants}
                initial="hidden"
                animate="visible"
                exit="exit"
              >
                <TableRow 
                  item={item} 
                  index={index + 1} 
                  isGainers={isGainers} 
                />
              </motion.div>
            ))}
          </AnimatePresence>
        </motion.div>
      </div>
    </motion.div>
  );
};