import { Tooltip } from "@mui/material";
import { truncateString } from "../../helpers/helperFunctions";
import { Asset } from "../../types/types";

interface SideBarCardProps {
  asset: Asset;
  showAirQuality: boolean;
  isSelected: boolean;
}

function SideBarCard({ asset, showAirQuality, isSelected}: SideBarCardProps) {
  const priceChange = 0;
  const priceBoxColor = priceChange > 0 ? 'bg-[var(--warm-orange)]' : 'bg-[var(--primary-dodger-blue)]';
  const active = isSelected ? ' bg-[var(--primary-dodger-blue)] text-white' : 'hover:bg-[var(--primary-dodger-blue)] bg-white';
  const activeText = isSelected ? 'text-white' : ' text-[var(--text-color-primary)] group-hover:text-white';
  const activePrice = isSelected ? 'bg-white text-[var(--text-color-primary)]' : '';
  return (
    <div className={`${active} group cursor-pointer md:w-full md:h-[5rem] px-[1.31rem] md:px-[0.75rem] min-[890px]:px-[1.31rem]  py-[1.44rem] rounded-[0.625rem]  mb-[0.62rem] text-white`}>
      <div className={`flex justify-between items-center`}>
        <Tooltip  title={asset.name} placement="top">
        <p className={`text-base ${activeText} md:text-left md:text-[16px] min-[890px]:text-base`}>{truncateString(asset.name,13)}</p>
        </Tooltip>
        <div className="flex items-center">
          <div className={`flex items-center ${activePrice} group-hover:bg-white group-hover:text-[var(--text-color-primary)] md:justify-center rounded-full md:rounded-[2.1875rem] py-[8px] px-[13px] md:py-[6px] md:px-[10px] min-[890px]:py-[8px] min-[890px]:px-[13px]    ${priceBoxColor}`}>
            <p className="text-center text-[16px] w-full md:text-[16px] min-[890px]:text-[16px]">
              {showAirQuality ? asset?.pm25 : '$' + asset.price}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SideBarCard;
