import { getPriceChangePrediction } from '../../api/api';
import { useState, useEffect } from 'react';

interface PriceChangePredictionProps { assetId: string }

export const PriceChangePrediction = ({ assetId }: PriceChangePredictionProps) => {
  const [priceChangePrediction, setPriceChangePrediction] = useState<number | null>(null);

  useEffect(() => {
    fetchPriceChangePrediction({assetId, setPriceChangePrediction});
  }, [assetId]);

  if (!priceChangePrediction) return <></>
  
  const changeDirection = priceChangePrediction >= 0 ? 'increase' : 'decrease'

  return (
    <div className="text-sm text-gray-500">
      ✨ AI Tip: <strong>Price is expected to {changeDirection} by {priceChangePrediction}% in an hour</strong>, due to an expected {changeDirection} in air quality (pm2.5)
    </div>
  )
}

interface fetchPriceChangePredictionProps {
  assetId: string
  setPriceChangePrediction: React.Dispatch<React.SetStateAction<number | null>>;
}

const fetchPriceChangePrediction = async ({assetId, setPriceChangePrediction}: fetchPriceChangePredictionProps) => {
  try {
    const prediction = await getPriceChangePrediction(assetId);
    if (prediction) {
      setPriceChangePrediction(parseFloat(Number(prediction).toFixed(2)));
    }
  } catch (error) {
    setPriceChangePrediction(1)
    console.error("Error fetching price change prediction:", error);
  }
};