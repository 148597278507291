import { useState } from 'react'
import Table from '../table/Table'
import { Column, LeaderboardValuesData } from '../../types/types'
import { useNavigate } from 'react-router-dom'

interface Props {
  tableValuesData:LeaderboardValuesData[]
  tableColumnsData:Column[]
}

const LeaderboardTable = ({ tableValuesData, tableColumnsData }: Props) => {
  const [data, setData] = useState< LeaderboardValuesData[]| []>(tableValuesData)
  const navigate = useNavigate();

  const _onRowClick = ( row : LeaderboardValuesData )=>{
    const { user_id } = row.data
    navigate(`/portfolio/${user_id}`)
  }

  return (
    <Table
      data={data}
      columns={tableColumnsData.filter(i => i.key != 'row_color')}
      setData={setData}
      noBorder
      isLeaderboard
      _onRowClick={_onRowClick}
    />
  )
}

export default LeaderboardTable