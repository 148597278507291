import { CustomShapeFunctionProps } from '../../types/types';

export const renderCustomShape = ({
  shapeProps,
  hoveredAssetId,
  getColorBasedOnAssetId,
  setHoveredPoint,
}: CustomShapeFunctionProps) => {
  const { cx, cy, payload } = shapeProps;
  let color;
  if (hoveredAssetId === null) {
    color = getColorBasedOnAssetId(payload.assetId);
  } else {
    color = payload.assetId === hoveredAssetId ? getColorBasedOnAssetId(payload.assetId) : 'rgba(211, 211, 211, 0.05)';
  }

  return <circle cx={cx} cy={cy} r={4} fill={color} stroke="#FFFFFF" strokeWidth="0.25" onMouseOver={() => setHoveredPoint(payload)} onMouseOut={() => setHoveredPoint(null)}
  />
};

