import { TableCell, Fade, TableHead, TableRow, TableSortLabel, Tooltip } from '@mui/material';
import { Column } from '../../types/types'
import ConditionalTooltip from './ConditionalTooltip';
type AssetTableHeadProps = {
  columns: Column[],
  orderBy: string,
  order: 'asc' | 'desc',
  handleSort: (property: string) => void
  isLeaderboard?: Boolean
};

const TableHeader: React.FC<AssetTableHeadProps> = ({ columns, orderBy, order, handleSort, isLeaderboard=false }) => {
  return (
    <TableHead>
      <Fade in={true} timeout={300}>
        <TableRow>
          {columns.map((headCell) => (
            <TableCell 
              align={headCell.isLeftAligned ? "left" : "right"} 
              key={headCell.key} 
              className={`
                whitespace-nowrap 
                ${headCell.key === 'name' ? 'sticky left-0' : ''} 
               ${isLeaderboard ?  'bg-[#F8F8F8] !border-b-[0px] !pb-[7px]': 'bg-white' }
                z-10
              `}
              style={{ fontWeight: 'bold' }}
            >

              <ConditionalTooltip title={headCell.tooltip}>
                <TableSortLabel 
                  active={orderBy === headCell.label} 
                  direction={order} 
                  onClick={() => handleSort(headCell.key)}
                >
                  {headCell.label}
                </TableSortLabel>
              </ConditionalTooltip>
            </TableCell>
          ))}
        </TableRow>
      </Fade>
    </TableHead>
  );
};

export default TableHeader;