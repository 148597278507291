import { CorrelationCharts } from './ScatterPlotsContainer';
import { motion } from 'framer-motion';

function MarketInfo() {
  return (
    <>
      <div className='px-4'>
        <TextLines />
        <CorrelationCharts />
      </div>
    </>
  );
}

const TextLines = () => {
  const texts = ['In this market Air Quality drives Asset Prices.', 'Dividends are based on Air Quality.', 'Traders connect Asset Prices with Dividends.']
  return (
    <div className='pb-12'>
      {texts.map((text, index) => (
        <motion.h2
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: index * 1.2, duration: 1 }}
          className="text-4xl mt-8 ml-2"
          key={index}
        >
          {text}
        </motion.h2>
      ))}
    </div>
  )
};

export default MarketInfo;