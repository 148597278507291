import React from 'react'
import IconTooltipWrapper from '../icons/IconTooltipWrapper'
import { InfoOutlined } from '@mui/icons-material'

interface InfoCardProps{
  tooltipMessage?:string|null
  bgColorClass?:string
  textColorClass:string
  valueColorClass:string
  mainText:string
  value:string
  marginClass?:string
}

const InfoCard = ({ tooltipMessage, bgColorClass, textColorClass, valueColorClass, mainText, value, marginClass }:InfoCardProps) => {

  return (
    <div className={`py-[28px] px-[31px] w-[49%] md:w-[24%] md:p-[20px] min-[1222px]:p-[40px] ${bgColorClass ? bgColorClass:`bg-[white]`} ${marginClass} rounded-[15px]`}>
      <div className="flex items-center mb-[11px] md:mb-[28px]">
      <h3 className={`font-semibold   max-[368px]:text-[11px] max-[390px]:text-[12px] max-[490px]:text-[14px] max-md:text-[15px]  md:text-[16px] min-[1015px]:text-[25px]  ${textColorClass}`}>{mainText}</h3>
      {tooltipMessage ? <IconTooltipWrapper tooltipMessage={tooltipMessage} icon={<InfoOutlined  sx={{ fontSize: {xs: 16, sm: 16, md: 24} } }  className='cursor-pointer  ml-[5px]  min-[1000px]:ml-[15px]' />}   /> :null}
      </div>
      <h3 className={`font-bold max-[360px]:text-[14px] max-[390px]:text-[18px] max-[490px]:text-[20px] max-md:text-[30px] md:text-[25px] min-[1210px]:text-[45px] ${valueColorClass}`}>{value}</h3>
    </div>
  )
}

export default InfoCard