import { TableData, NumberedTableData, LineDataPoint, ScatterChartDataPoint, ScatterChartData, ChartTimeFrame, LineChartData, Asset, Pm25Hourly, Pm25Daily, TradePresentationItem } from '../types/types';

export const createMultiScatterChartData = () => {
  const airMeasure = createScatterChartData()
  const priceDividend = createScatterChartData();
  return {airMeasure, priceDividend}
}

export const createScatterChartData = (
  xAxisLabel: string = "[Sample] Price",
  yAxisLabel: string = "PM2.5 10 Minute Average",
  correlationValue: number = 0
): ScatterChartData => {
  const dataPoints = generateHighCorrelationData(1000)
  const assetMap: { [key: number]: string } = {};

  dataPoints.forEach((point) => {
    assetMap[point.assetId] = `Asset ${point.assetId}`;
  });

  return {
    xAxis: xAxisLabel,
    yAxis: yAxisLabel,
    assets: assetMap,
    data: dataPoints,
    correlation: correlationValue,
  };
};

const getRandomNumber = (min: number, max: number): number => {
  return Math.random() * (max - min) + min;
};

export const generateHighCorrelationData = (numPoints: number): ScatterChartDataPoint[] => {
  const data: ScatterChartDataPoint[] = [];
  for (let i = 0; i < numPoints; i++) {
    const x = getRandomNumber(1000, 5000);
    const y = x * (1 + getRandomNumber(0,2));
    const assetId = Math.round(getRandomNumber(1, 10));
    data.push({ x, y, assetId, cycleId: 1 });
  }
  return data;
};


export const generateDividendLineChartData = (timeFrame: ChartTimeFrame, numDataPoints: number = 100): LineChartData => {
  const dividendHistory = generateLineData(timeFrame, numDataPoints)
  const assetName = ""
  return { dividendHistory, assetName }
}

export const generatePriceLineChartData = (timeFrame: ChartTimeFrame, numDataPoints: number = 100): LineChartData => {
  const priceHistory = generateLineData(timeFrame, numDataPoints)
  const assetName = "Boulder"
  return { priceHistory, assetName }
}
// Function to generate data based on the given timeframe
export const generateLineData = (timeFrame: ChartTimeFrame, numDataPoints: number = 100): LineDataPoint[] => {
  const data: LineDataPoint[] = [];
  let currentDate = new Date();
  let currentPrice = 2700; // Initial price

  for (let i = 0; i < numDataPoints; i++) {
    const percentageFluctuation = (Math.random() * 2 - 1) / 100; // Fluctuate price by up to +/- 1%
    currentPrice *= (1 + percentageFluctuation);

    const dataPoint: LineDataPoint = {
      date: currentDate.toUTCString(),
      value: parseFloat(currentPrice.toFixed(4)), // Limit to 4 decimal places
    };

    data.push(dataPoint);

    switch (timeFrame) {
      case 'HOUR':
        currentDate = new Date(currentDate.getTime() + 60 * 1000); // Add 1 minute
        break;
      case '5HOUR':
        currentDate = new Date(currentDate.getTime() + 5 * 60 * 60 * 1000); // Add 5 hours
        break;
      case 'DAY':
        currentDate = new Date(currentDate.getTime() + 24 * 60 * 60 * 1000); // Add 1 day
        break;
      case 'WEEK':
        currentDate = new Date(currentDate.getTime() + 7 * 24 * 60 * 60 * 1000); // Add 1 week
        break;
    }
  }

  return data;
}

const generateRandompm25Value = (): number => {
  return Math.random() * (35 - 2) + 2;
}

export const generateAssetsData = () => {
    const assets: Asset[] = [];
    const cities = ["New York", "Los Angeles", "Chicago", "Houston", "Phoenix", "Philadelphia", "San Antonio", "San Diego", "Dallas", "San Jose"]
  
    for (let i = 0; i < cities.length; i++) {
      const asset: Asset = {
        id: i,
        name: cities[i],
        price: parseFloat((Math.random() * 2000).toFixed(2)),
        pm25: generateRandompm25Value(),
      };
    
      assets.push(asset);
    }
  
    return assets;
}

export const generatePm25HourlyData = (): Pm25Hourly[] => {
    const data = []
    for (let i = 0; i < 24; i++) {
      const asset: Pm25Hourly = {
        avg_pm_25: generateRandompm25Value(),
        hour_of_day: i,
      };
    
      data.push(asset);
    }
  
    return data;
}
export const generatePm25DailyData = (): Pm25Daily[] => {
    const data = []
    for (let i = 0; i < 7; i++) {
      const days = ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"]
      const datapoint: Pm25Daily = {
        avg_pm_25: generateRandompm25Value(),
        index: i,
        day_of_week: days[i],
      };
    
      data.push(datapoint);
    }
  
    return data;
}

interface tradePresentationData {
  message: string
  trade_presentation_queue: TradePresentationItem[]
}

export const generateTradePresentationData = (): tradePresentationData => {
  return {
    message: "Fetching trade presentation queue executed successfully",
    trade_presentation_queue:[
      {
        "absolute_pnl": 1244.80,
        "asset_id": 6,
        "asset_name": "New York",
        "asset_quantity": 3443.03,
        "id": 26979,
        "percentage_pnl": 121.57,
        "price": 0.97,
        "trade_type": "SELL",
        "user_id": 30
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 7,
        "asset_name": "Toronto",
        "asset_quantity": 789.81,
        "id": 26980,
        "percentage_pnl": 0.00,
        "price": 0.09,
        "trade_type": "BUY",
        "user_id": 30
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 5,
        "asset_name": "Toronto",
        "asset_quantity": 14843.26,
        "id": 26981,
        "percentage_pnl": 0.00,
        "price": 0.93,
        "trade_type": "BUY",
        "user_id": 30
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 8,
        "asset_name": "Toronto",
        "asset_quantity": 258.91,
        "id": 26982,
        "percentage_pnl": 0.00,
        "price": 1.48,
        "trade_type": "BUY",
        "user_id": 30
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 9,
        "asset_name": "Toronto",
        "asset_quantity": 1026.73,
        "id": 26983,
        "percentage_pnl": 0.00,
        "price": 0.79,
        "trade_type": "BUY",
        "user_id": 30
      },
      {
        "absolute_pnl": -5671.99,
        "asset_id": 5,
        "asset_name": "Toronto",
        "asset_quantity": 8271.15,
        "id": 26984,
        "percentage_pnl": -44.21,
        "price": 0.94,
        "trade_type": "SELL",
        "user_id": 29
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 2,
        "asset_name": "Toronto",
        "asset_quantity": 10222.60,
        "id": 26985,
        "percentage_pnl": 0.00,
        "price": 0.28,
        "trade_type": "BUY",
        "user_id": 29
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 3,
        "asset_name": "Toronto",
        "asset_quantity": 83.33,
        "id": 26986,
        "percentage_pnl": 0.00,
        "price": 3.27,
        "trade_type": "BUY",
        "user_id": 29
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 8,
        "asset_name": "Toronto",
        "asset_quantity": 268.72,
        "id": 26987,
        "percentage_pnl": 0.00,
        "price": 1.49,
        "trade_type": "BUY",
        "user_id": 29
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 6,
        "asset_name": "Toronto",
        "asset_quantity": 5316.83,
        "id": 26988,
        "percentage_pnl": 0.00,
        "price": 0.98,
        "trade_type": "BUY",
        "user_id": 29
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 9,
        "asset_name": "Toronto",
        "asset_quantity": 1090.99,
        "id": 26989,
        "percentage_pnl": 0.00,
        "price": 0.80,
        "trade_type": "BUY",
        "user_id": 29
      },
      {
        "absolute_pnl": -46.61,
        "asset_id": 1,
        "asset_name": "Toronto",
        "asset_quantity": 900.77,
        "id": 26990,
        "percentage_pnl": -17.71,
        "price": 0.42,
        "trade_type": "SELL",
        "user_id": 41
      },
      {
        "absolute_pnl": -46.55,
        "asset_id": 2,
        "asset_name": "Toronto",
        "asset_quantity": 376.84,
        "id": 26991,
        "percentage_pnl": -296.22,
        "price": 0.28,
        "trade_type": "SELL",
        "user_id": 41
      },
      {
        "absolute_pnl": -4115.24,
        "asset_id": 5,
        "asset_name": "Toronto",
        "asset_quantity": 6972.78,
        "id": 26992,
        "percentage_pnl": -50.98,
        "price": 0.91,
        "trade_type": "SELL",
        "user_id": 41
      },
      {
        "absolute_pnl": -365.26,
        "asset_id": 3,
        "asset_name": "Toronto",
        "asset_quantity": 255.30,
        "id": 26993,
        "percentage_pnl": -88.73,
        "price": 3.25,
        "trade_type": "SELL",
        "user_id": 41
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 4,
        "asset_name": "Toronto",
        "asset_quantity": 414.39,
        "id": 26994,
        "percentage_pnl": 0.00,
        "price": 3.29,
        "trade_type": "BUY",
        "user_id": 41
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 8,
        "asset_name": "Toronto",
        "asset_quantity": 1343.76,
        "id": 26995,
        "percentage_pnl": 0.00,
        "price": 1.51,
        "trade_type": "BUY",
        "user_id": 41
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 6,
        "asset_name": "Toronto",
        "asset_quantity": 644.54,
        "id": 26996,
        "percentage_pnl": 0.00,
        "price": 0.99,
        "trade_type": "BUY",
        "user_id": 41
      },
      {
        "absolute_pnl": -78.62,
        "asset_id": 3,
        "asset_name": "Toronto",
        "asset_quantity": 66.05,
        "id": 26997,
        "percentage_pnl": -221.52,
        "price": 3.24,
        "trade_type": "SELL",
        "user_id": 40
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 2,
        "asset_name": "Toronto",
        "asset_quantity": 6324.86,
        "id": 26998,
        "percentage_pnl": 0.00,
        "price": 0.28,
        "trade_type": "BUY",
        "user_id": 40
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 5,
        "asset_name": "Toronto",
        "asset_quantity": 5148.53,
        "id": 26999,
        "percentage_pnl": 0.00,
        "price": 0.91,
        "trade_type": "BUY",
        "user_id": 40
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 8,
        "asset_name": "Toronto",
        "asset_quantity": 1004.67,
        "id": 27000,
        "percentage_pnl": 0.00,
        "price": 1.53,
        "trade_type": "BUY",
        "user_id": 40
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 9,
        "asset_name": "Toronto",
        "asset_quantity": 483.57,
        "id": 27001,
        "percentage_pnl": 0.00,
        "price": 0.81,
        "trade_type": "BUY",
        "user_id": 40
      },
      {
        "absolute_pnl": -6541.42,
        "asset_id": 5,
        "asset_name": "Toronto",
        "asset_quantity": 11521.34,
        "id": 27002,
        "percentage_pnl": -41.02,
        "price": 0.89,
        "trade_type": "SELL",
        "user_id": 39
      },
      {
        "absolute_pnl": -9.13,
        "asset_id": 3,
        "asset_name": "Toronto",
        "asset_quantity": 31.40,
        "id": 27003,
        "percentage_pnl": -431.69,
        "price": 3.23,
        "trade_type": "SELL",
        "user_id": 39
      },
      {
        "absolute_pnl": 1237.39,
        "asset_id": 6,
        "asset_name": "Toronto",
        "asset_quantity": 4444.05,
        "id": 27004,
        "percentage_pnl": 60.65,
        "price": 0.98,
        "trade_type": "SELL",
        "user_id": 39
      },
      {
        "absolute_pnl": 212.43,
        "asset_id": 9,
        "asset_name": "Toronto",
        "asset_quantity": 824.76,
        "id": 27005,
        "percentage_pnl": 47.72,
        "price": 0.81,
        "trade_type": "SELL",
        "user_id": 39
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 2,
        "asset_name": "Toronto",
        "asset_quantity": 4870.30,
        "id": 27006,
        "percentage_pnl": 0.00,
        "price": 0.29,
        "trade_type": "BUY",
        "user_id": 39
      },
      {
        "absolute_pnl": -5889.82,
        "asset_id": 2,
        "asset_name": "Toronto",
        "asset_quantity": 35417.52,
        "id": 27007,
        "percentage_pnl": -116.02,
        "price": 0.28,
        "trade_type": "SELL",
        "user_id": 1
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 6,
        "asset_name": "Toronto",
        "asset_quantity": 28072.80,
        "id": 27008,
        "percentage_pnl": 0.00,
        "price": 0.93,
        "trade_type": "SELL",
        "user_id": 1
      },
      {
        "absolute_pnl": 1189.46,
        "asset_id": 8,
        "asset_name": "Toronto",
        "asset_quantity": 9341.28,
        "id": 27009,
        "percentage_pnl": 18.78,
        "price": 1.43,
        "trade_type": "SELL",
        "user_id": 1
      },
      {
        "absolute_pnl": -427.60,
        "asset_id": 9,
        "asset_name": "Toronto",
        "asset_quantity": 8724.73,
        "id": 27010,
        "percentage_pnl": -31.82,
        "price": 0.76,
        "trade_type": "SELL",
        "user_id": 1
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 3,
        "asset_name": "Toronto",
        "asset_quantity": 4020.74,
        "id": 27011,
        "percentage_pnl": 0.00,
        "price": 3.41,
        "trade_type": "BUY",
        "user_id": 1
      },
      {
        "absolute_pnl": 49.28,
        "asset_id": 1,
        "asset_name": "Toronto",
        "asset_quantity": 8032.71,
        "id": 27012,
        "percentage_pnl": 1.52,
        "price": 0.41,
        "trade_type": "SELL",
        "user_id": 2
      },
      {
        "absolute_pnl": -2151.70,
        "asset_id": 4,
        "asset_name": "Toronto",
        "asset_quantity": 5209.43,
        "id": 27013,
        "percentage_pnl": -19.82,
        "price": 3.18,
        "trade_type": "SELL",
        "user_id": 2
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 7,
        "asset_name": "Toronto",
        "asset_quantity": 19625.61,
        "id": 27014,
        "percentage_pnl": 0.00,
        "price": 0.09,
        "trade_type": "SELL",
        "user_id": 2
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 3,
        "asset_name": "Toronto",
        "asset_quantity": 1118.82,
        "id": 27015,
        "percentage_pnl": 0.00,
        "price": 3.62,
        "trade_type": "BUY",
        "user_id": 2
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 5,
        "asset_name": "Toronto",
        "asset_quantity": 8241.73,
        "id": 27016,
        "percentage_pnl": 0.00,
        "price": 0.89,
        "trade_type": "BUY",
        "user_id": 2
      },
      {
        "absolute_pnl": 197.22,
        "asset_id": 2,
        "asset_name": "Toronto",
        "asset_quantity": 7948.52,
        "id": 27017,
        "percentage_pnl": 11.81,
        "price": 0.26,
        "trade_type": "SELL",
        "user_id": 3
      },
      {
        "absolute_pnl": 1299.41,
        "asset_id": 6,
        "asset_name": "Toronto",
        "asset_quantity": 5217.65,
        "id": 27018,
        "percentage_pnl": 63.22,
        "price": 0.89,
        "trade_type": "SELL",
        "user_id": 3
      },
      {
        "absolute_pnl": 277.63,
        "asset_id": 7,
        "asset_name": "Toronto",
        "asset_quantity": 15271.05,
        "id": 27019,
        "percentage_pnl": 42.91,
        "price": 0.09,
        "trade_type": "SELL",
        "user_id": 3
      },
      {
        "absolute_pnl": -132.01,
        "asset_id": 10,
        "asset_name": "Toronto",
        "asset_quantity": 449.37,
        "id": 27020,
        "percentage_pnl": -46.83,
        "price": 1.50,
        "trade_type": "SELL",
        "user_id": 3
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 1,
        "asset_name": "Toronto",
        "asset_quantity": 4163.49,
        "id": 27021,
        "percentage_pnl": 0.00,
        "price": 0.40,
        "trade_type": "BUY",
        "user_id": 3
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 3,
        "asset_name": "Toronto",
        "asset_quantity": 1285.68,
        "id": 27022,
        "percentage_pnl": 0.00,
        "price": 3.72,
        "trade_type": "BUY",
        "user_id": 3
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 4,
        "asset_name": "Toronto",
        "asset_quantity": 1470.85,
        "id": 27023,
        "percentage_pnl": 0.00,
        "price": 3.13,
        "trade_type": "BUY",
        "user_id": 3
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 5,
        "asset_name": "Toronto",
        "asset_quantity": 9387.14,
        "id": 27024,
        "percentage_pnl": 0.00,
        "price": 0.92,
        "trade_type": "BUY",
        "user_id": 3
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 8,
        "asset_name": "Toronto",
        "asset_quantity": 2684.76,
        "id": 27025,
        "percentage_pnl": 0.00,
        "price": 1.37,
        "trade_type": "BUY",
        "user_id": 3
      },
      {
        "absolute_pnl": -164.95,
        "asset_id": 1,
        "asset_name": "Toronto",
        "asset_quantity": 3438.78,
        "id": 27026,
        "percentage_pnl": -29.62,
        "price": 0.40,
        "trade_type": "SELL",
        "user_id": 4
      },
      {
        "absolute_pnl": -613.04,
        "asset_id": 8,
        "asset_name": "Toronto",
        "asset_quantity": 2287.16,
        "id": 27027,
        "percentage_pnl": -25.18,
        "price": 1.37,
        "trade_type": "SELL",
        "user_id": 4
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 2,
        "asset_name": "Toronto",
        "asset_quantity": 1017.02,
        "id": 27028,
        "percentage_pnl": 0.00,
        "price": 0.26,
        "trade_type": "BUY",
        "user_id": 4
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 3,
        "asset_name": "Toronto",
        "asset_quantity": 1244.61,
        "id": 27029,
        "percentage_pnl": 0.00,
        "price": 3.84,
        "trade_type": "BUY",
        "user_id": 4
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 5,
        "asset_name": "Toronto",
        "asset_quantity": 9001.96,
        "id": 27030,
        "percentage_pnl": 0.00,
        "price": 0.96,
        "trade_type": "BUY",
        "user_id": 4
      },
      {
        "absolute_pnl": -422.47,
        "asset_id": 3,
        "asset_name": "Toronto",
        "asset_quantity": 435.46,
        "id": 27031,
        "percentage_pnl": -25.61,
        "price": 3.86,
        "trade_type": "SELL",
        "user_id": 5
      },
      {
        "absolute_pnl": 2.07,
        "asset_id": 4,
        "asset_name": "Toronto",
        "asset_quantity": 119.06,
        "id": 27032,
        "percentage_pnl": 12.32,
        "price": 3.14,
        "trade_type": "SELL",
        "user_id": 5
      },
      {
        "absolute_pnl": 17.79,
        "asset_id": 9,
        "asset_name": "Toronto",
        "asset_quantity": 211.23,
        "id": 27033,
        "percentage_pnl": 13.01,
        "price": 0.73,
        "trade_type": "SELL",
        "user_id": 5
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 1,
        "asset_name": "Toronto",
        "asset_quantity": 1834.06,
        "id": 27034,
        "percentage_pnl": 0.00,
        "price": 0.40,
        "trade_type": "BUY",
        "user_id": 5
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 5,
        "asset_name": "Toronto",
        "asset_quantity": 2966.88,
        "id": 27035,
        "percentage_pnl": 0.00,
        "price": 0.98,
        "trade_type": "BUY",
        "user_id": 5
      },
      {
        "absolute_pnl": -66.37,
        "asset_id": 1,
        "asset_name": "Toronto",
        "asset_quantity": 578.73,
        "id": 27036,
        "percentage_pnl": -904.99,
        "price": 0.40,
        "trade_type": "SELL",
        "user_id": 6
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 3,
        "asset_name": "Toronto",
        "asset_quantity": 32.80,
        "id": 27037,
        "percentage_pnl": 0.00,
        "price": 3.86,
        "trade_type": "BUY",
        "user_id": 6
      },
      {
        "absolute_pnl": 0.00,
        "asset_id": 5,
        "asset_name": "Toronto",
        "asset_quantity": 409.34,
        "id": 27038,
        "percentage_pnl": 0.00,
        "price": 0.99,
        "trade_type": "BUY",
        "user_id": 6
      }
    ]
  }
}

export const generateAssetInfoData = () => {
  return {
    "columns": [
        {
            "isBold": true,
            "isLeftAligned": true,
            "key": "name",
            "label": "Name",
            "type": "text"
        },
        {
            "isBold": true,
            "isLeftAligned": false,
            "key": "market_cap",
            "label": "Market Cap",
            "type": "currency"
        },
        {
            "isBold": true,
            "isLeftAligned": false,
            "key": "price",
            "label": "Price",
            "type": "currency"
        },
        {
            "isBold": true,
            "isLeftAligned": false,
            "key": "1h_%",
            "label": "1h %",
            "type": "percentage"
        },
        {
            "isBold": true,
            "isLeftAligned": false,
            "key": "24h_%",
            "label": "24h %",
            "type": "percentage"
        },
        {
            "isBold": false,
            "isLeftAligned": false,
            "key": "annualized_yield",
            "label": "APR %",
            "tooltip": "Annualized Percent Yield. This projects the last air-quality-based yield over a year and divides it by the current price.",
            "type": "percentage"
        },
        {
            "isBold": false,
            "isLeftAligned": false,
            "key": "24h_dividends",
            "label": "24h Yield",
            "type": "currency"
        },
        {
            "isBold": false,
            "isLeftAligned": false,
            "key": "one_day_trade_vol",
            "label": "24h Vol",
            "type": "currency"
        },
        {
            "isBold": false,
            "isLeftAligned": false,
            "key": "circulating_supply",
            "label": "Circulating Supply",
            "type": "number"
        },
        {
            "isBold": false,
            "isLeftAligned": false,
            "key": "pm_25",
            "label": "Pm2.5",
            "type": "number"
        },
        {
            "isBold": true,
            "isLeftAligned": false,
            "key": "pm_25_1hr_%",
            "label": "Pm2.5 1h %",
            "type": "percentage"
        },
        {
            "isBold": true,
            "isLeftAligned": false,
            "key": "pm_25_24hr_%",
            "label": "Pm2.5 24h %",
            "type": "percentage"
        },
        {
            "isBold": true,
            "isLeftAligned": false,
            "key": "%_in_liquidity_pool",
            "label": "% in LPs",
            "type": "percentage"
        }
    ],
    "data": [
        {
            "data": {
                "%_in_liquidity_pool": "1.50",
                "1h_%": "1.91",
                "24h_%": "19.53",
                "24h_dividends": "89.84M",
                "annualized_yield": "61.21",
                "circulating_supply": "14.85M",
                "market_cap": "74.81B",
                "name": "New York City",
                "one_day_trade_vol": "1.72B",
                "pm_25": "1.75",
                "pm_25_1hr_%": "-7.67",
                "pm_25_24hr_%": "-31.98",
                "price": "5.04K"
            },
            "id": 1
        },
        {
            "data": {
                "%_in_liquidity_pool": "1.47",
                "1h_%": "19.96",
                "24h_%": "19.45",
                "24h_dividends": "88.11M",
                "annualized_yield": "61.22",
                "circulating_supply": "15.56M",
                "market_cap": "74.78B",
                "name": "Toronto",
                "one_day_trade_vol": "4.09B",
                "pm_25": "1.84",
                "pm_25_1hr_%": "-30.64",
                "pm_25_24hr_%": "-19.19",
                "price": "4.81K"
            },
            "id": 2
        },
        {
            "data": {
                "%_in_liquidity_pool": "5.03",
                "1h_%": "-7.55",
                "24h_%": "21.97",
                "24h_dividends": "54.40M",
                "annualized_yield": "60.92",
                "circulating_supply": "2.24M",
                "market_cap": "44.14B",
                "name": "San Francisco",
                "one_day_trade_vol": "12.61B",
                "pm_25": "9.64",
                "pm_25_1hr_%": "0.16",
                "pm_25_24hr_%": "-2.37",
                "price": "19.74K"
            },
            "id": 3
        },
        {
            "data": {
                "%_in_liquidity_pool": "4.81",
                "1h_%": "-11.66",
                "24h_%": "-11.27",
                "24h_dividends": "86.69M",
                "annualized_yield": "60.91",
                "circulating_supply": "1.89M",
                "market_cap": "57.13B",
                "name": "Miami",
                "one_day_trade_vol": "8.50B",
                "pm_25": "4.40",
                "pm_25_1hr_%": "-20.36",
                "pm_25_24hr_%": "203.45",
                "price": "30.23K"
            },
            "id": 4
        },
        {
            "data": {
                "%_in_liquidity_pool": "9.39",
                "1h_%": "-1.76",
                "24h_%": "47.52",
                "24h_dividends": "54.88M",
                "annualized_yield": "60.91",
                "circulating_supply": "5.09M",
                "market_cap": "55.60B",
                "name": "Napa Valley",
                "one_day_trade_vol": "21.16B",
                "pm_25": "6.37",
                "pm_25_1hr_%": "-12.89",
                "pm_25_24hr_%": "-36.28",
                "price": "10.93K"
            },
            "id": 5
        },
        {
            "data": {
                "%_in_liquidity_pool": "2.84",
                "1h_%": "0.38",
                "24h_%": "71.25",
                "24h_dividends": "58.30M",
                "annualized_yield": "60.92",
                "circulating_supply": "9.73M",
                "market_cap": "62.58B",
                "name": "London",
                "one_day_trade_vol": "15.09B",
                "pm_25": "4.57",
                "pm_25_1hr_%": "3.07",
                "pm_25_24hr_%": "-48.52",
                "price": "6.43K"
            },
            "id": 6
        },
        {
            "data": {
                "%_in_liquidity_pool": "40.64",
                "1h_%": "1.46",
                "24h_%": "18.44",
                "24h_dividends": "35.14M",
                "annualized_yield": "61.60",
                "circulating_supply": "122.18M",
                "market_cap": "48.49B",
                "name": "Mexico City",
                "one_day_trade_vol": "35.28B",
                "pm_25": "18.41",
                "pm_25_1hr_%": "-5.19",
                "pm_25_24hr_%": "-12.94",
                "price": "396.90"
            },
            "id": 7
        },
        {
            "data": {
                "%_in_liquidity_pool": "3.64",
                "1h_%": "-19.32",
                "24h_%": "-58.94",
                "24h_dividends": "63.24M",
                "annualized_yield": "60.93",
                "circulating_supply": "7.30M",
                "market_cap": "25.85B",
                "name": "Sydney",
                "one_day_trade_vol": "22.08B",
                "pm_25": "25.18",
                "pm_25_1hr_%": "-4.35",
                "pm_25_24hr_%": "785.15",
                "price": "3.54K"
            },
            "id": 8
        },
        {
            "data": {
                "%_in_liquidity_pool": "1.90",
                "1h_%": "1.87",
                "24h_%": "47.26",
                "24h_dividends": "64.53M",
                "annualized_yield": "60.91",
                "circulating_supply": "9.20M",
                "market_cap": "75.23B",
                "name": "Chicago",
                "one_day_trade_vol": "10.94B",
                "pm_25": "1.68",
                "pm_25_1hr_%": "-7.29",
                "pm_25_24hr_%": "-62.14",
                "price": "8.18K"
            },
            "id": 9
        },
        {
            "data": {
                "%_in_liquidity_pool": "4.01",
                "1h_%": "1.43",
                "24h_%": "22.58",
                "24h_dividends": "69.14M",
                "annualized_yield": "60.92",
                "circulating_supply": "2.76M",
                "market_cap": "56.13B",
                "name": "Philadelphia",
                "one_day_trade_vol": "15.47B",
                "pm_25": "4.98",
                "pm_25_1hr_%": "-11.51",
                "pm_25_24hr_%": "-16.14",
                "price": "20.31K"
            },
            "id": 10
        }
    ],
    "message": "Assets Chart Info fetched successfully"
  }
}