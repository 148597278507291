import React, { useState } from 'react';
import { AssetsType} from '../../types/types';
import { getColorBasedOnAssetId } from './utils';

interface ScatterLegendProps {
  assets: AssetsType;
  setHoveredAssetId: React.Dispatch<React.SetStateAction<number | null>>;
  filteredAssets: Set<number>;
  setFilteredAssets: React.Dispatch<React.SetStateAction<Set<number>>>;
}


export const ScatterLegend = ({ assets, setHoveredAssetId, filteredAssets, setFilteredAssets }: ScatterLegendProps) => {
  const [selectedAssetId, setSelectedAssetId] = useState<number | null>(null);

  const toggleFilter = (assetId: number) => {
    const newFilteredAssets = new Set(filteredAssets);
    if (newFilteredAssets.has(assetId)) {
      newFilteredAssets.delete(assetId);
      setHoveredAssetId(assetId);
    } else {
      setHoveredAssetId(null);
      setSelectedAssetId(null);
      newFilteredAssets.add(assetId);
    }
    setFilteredAssets(newFilteredAssets);
  };

  const handleMouseOver = (assetId: number) => {
    if (selectedAssetId === null && !filteredAssets.has(assetId)) {
      setHoveredAssetId(assetId);
    }
  };

  const handleMouseOut = () => {
    if (selectedAssetId === null) {
      setHoveredAssetId(null);
    }
  };

  const handleOnClick = (assetId: number) => {
    if (filteredAssets.has(assetId)) {
      toggleFilter(assetId)
      return
    }
    if (selectedAssetId === assetId) {
      setSelectedAssetId(null);
      setHoveredAssetId(null);
    } else {
      setSelectedAssetId(assetId);
      setHoveredAssetId(assetId);
    }
  };

  return (
    <div className="flex flex-wrap pt-4 items-center">
      {Object.entries(assets).map(([assetIdStr, assetName]) => {
        const assetId = Number(assetIdStr);
        if (assetName === 'Asset 7') assetName = "FDERppp derpp";
        const isFaded = selectedAssetId !== null && selectedAssetId !== assetId;
        const isSelected = selectedAssetId === assetId;
        const isFiltered = filteredAssets.has(assetId)
        return (
          <div
            key={assetId}
            onMouseOver={() => handleMouseOver(assetId)}
            onMouseOut={handleMouseOut}
            onClick={() => handleOnClick(assetId)}
            className={`flex justify-start items-center mx-0.5 my-2 p-2 select-none cursor-pointer transition-all duration-150 ${isFaded || isFiltered ? 'opacity-40' : 'opacity-100'} hover:scale-105 ${isSelected ? 'border-1 border-gray-300 rounded-md shadow-md' : ''}`}
          >
            <span
              className="w-3 h-3 mr-1 rounded-full"
              style={{ backgroundColor: getColorBasedOnAssetId(assetId) }}
            />
            <span className="truncate text-xs">{assetName}</span>
            <i
              className={`fas fa-eye${isFiltered ? '-slash' : ''} ml-2 cursor-pointer ${isFiltered ? ' text-gray-500' : ' text-gray-300'} hover:text-gray-500`}
              style={{ fontSize: '0.6rem' }}
              onClick={(e) => {
                e.stopPropagation(); // Prevent the main div onClick from triggering
                toggleFilter(assetId);
              }}
            ></i>
          </div>
        );
      })}
    </div>
  );
};