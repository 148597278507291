import React from 'react'
import InfoCard from '../../infoCard/InfoCard'
import { tooltipMessageAnnualProfiit, tooltipMessageRoi } from '../consts'
import { PortfolioInfoCardData } from '../../../types/types'

interface FinancialInfoProps {
  data: PortfolioInfoCardData
}

const FinancialInfo = ({ data }:FinancialInfoProps) => {
  const roiPercentage = data['roiPercent'] === '100.00' ? parseFloat(data['roiPercent']).toFixed() :  data['roiPercent'] 

  const getValueColorClass =(value:string)=>{
    let parsedValue = parseFloat(value)
    return parsedValue < 0  ? 'text-[var(--warm-orange)]' : 'text-[var(--turquoise-green)]'
  }
  
  return (
    <div className='flex flex-wrap justify-between mb-[20px] md:mb-[40px]'>
      <InfoCard
        mainText='Cash balance'
        value={`$${data['cashBalance']}`}
        textColorClass='text-[var(--text-color-primary)]'
        valueColorClass='text-[var(--text-color-primary)]'
        marginClass='max-[400px]:mb-[6px] max-md:mb-[10px]'
      />
      <InfoCard
        mainText='Assets Value'
        value={`$${data['assetsValue']}`}
        bgColorClass='bg-[var(--primary-dodger-blue)]'
        textColorClass='text-white'
        valueColorClass='text-white'
        marginClass='max-[400px]:mb-[6px] max-md:mb-[10px]'

      />
      <InfoCard
        mainText='Annual Profit'
        value={`$${data['annualProfit']}`}
        textColorClass='text-[var(--text-color-primary)]'
        valueColorClass={getValueColorClass(data['annualProfit'])}
        tooltipMessage={tooltipMessageAnnualProfiit}
      />
      <InfoCard
        mainText='ROI'
        value={`${roiPercentage}%`}
        textColorClass='text-[var(--text-color-primary)]'
        valueColorClass={getValueColorClass(data['roiPercent'])}
        tooltipMessage={tooltipMessageRoi}
      />
    </div>
  )
}

export default FinancialInfo
