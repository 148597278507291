// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.sidebar-card-container {
  border-radius: 0.625rem;
  background: #FFF;
}

.sidebar-card-container:hover {
  background: #5E83FF;
}

.sidebar-price-container {
  border-radius: 0.625rem;
  color: #FFF;
  background: #5E83FF;
}

.sidebar-card-container:hover .sidebar-price-container{
  color: #424242;
  background: #FFF;
}

.sidebar-card-container:hover {
  color: #FFF;
}

.card-active {
  color: #FFF;
  background: #5E83FF;
}
.card-active .sidebar-price-container {
  color: #424242;
  background: #FFF;
}`, "",{"version":3,"sources":["webpack://./src/components/sideBarList/styles.css"],"names":[],"mappings":"AAAA;EACE,uBAAuB;EACvB,gBAAgB;AAClB;;AAEA;EACE,mBAAmB;AACrB;;AAEA;EACE,uBAAuB;EACvB,WAAW;EACX,mBAAmB;AACrB;;AAEA;EACE,cAAc;EACd,gBAAgB;AAClB;;AAEA;EACE,WAAW;AACb;;AAEA;EACE,WAAW;EACX,mBAAmB;AACrB;AACA;EACE,cAAc;EACd,gBAAgB;AAClB","sourcesContent":[".sidebar-card-container {\n  border-radius: 0.625rem;\n  background: #FFF;\n}\n\n.sidebar-card-container:hover {\n  background: #5E83FF;\n}\n\n.sidebar-price-container {\n  border-radius: 0.625rem;\n  color: #FFF;\n  background: #5E83FF;\n}\n\n.sidebar-card-container:hover .sidebar-price-container{\n  color: #424242;\n  background: #FFF;\n}\n\n.sidebar-card-container:hover {\n  color: #FFF;\n}\n\n.card-active {\n  color: #FFF;\n  background: #5E83FF;\n}\n.card-active .sidebar-price-container {\n  color: #424242;\n  background: #FFF;\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
