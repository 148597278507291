import React, { useState, useEffect } from 'react';
import { getAssetInfo } from '../../api/api'
import { Typography, Box } from '@mui/material';
import ErrorMessage from '../error/ErrorMessage';

interface AssetInfoProps {
  assetId: number
}

interface InfoProps {
  label: string;
  value: string;
}

const Info: React.FC<InfoProps> = ({ label, value }) => (
  <Box sx={{ display: 'flex', justifyContent: 'space-between', borderBottom: '1px solid var(--grayish-green)', marginTop: '20px' }}>
    <Typography variant="body1">{label}</Typography>
    <Typography variant="subtitle1" fontWeight="fontWeightBold">{value}</Typography>
  </Box>
);

const AssetInfo: React.FC<AssetInfoProps> = ({ assetId }) => {
  const [data, setData] = useState<any[]>([]);
  const [error, setError] = useState<string | null>(null);

  async function fetchAssetInfo(assetId: number) {
    try {
      const assetInfo = await getAssetInfo(assetId);
      setData(assetInfo);
    } catch (error: any) {
      setError("Something went wrong! Failed to fetch asset info.");
    }
  }

  useEffect(() => {
    fetchAssetInfo(assetId);
  }, [assetId]);

  if (error) {
    return <ErrorMessage message={error} />
  }

  // Convert array of objects into a single object
  const singleObject = data.reduce((acc, curr) => ({ ...acc, ...curr }), {});

  // Convert single object into an array of [key, value] pairs
  const keyValueArray = Object.entries(singleObject);

  // Split the array into two halves
  const halfLength = Math.ceil(keyValueArray.length / 2);
  const firstHalf = keyValueArray.slice(0, halfLength);
  const secondHalf = keyValueArray.slice(halfLength);

  return (
    <>
      <div style={{ fontFamily: 'Arial, sans-serif', color: '#333', padding: '2.38rem', background: "#FFF", borderRadius: "0.9375rem" }} className="md:text-[#FFF] hidden md:grid">
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <div style={{ width: '45%', padding: '10px' }}>
            {firstHalf.map(([key, value]) => (
              <Info label={key as string} value={value as string} key={key as string} />
            ))}
          </div>
          <div style={{ width: '45%', padding: '10px' }}>
            {secondHalf.map(([key, value]) => (
              <Info label={key as string} value={value as string} key={key as string} />
            ))}
          </div>
        </Box>
      </div>
      <div className="flex flex-col justify-between md:hidden">
        <Box sx={{borderRadius: "0.9375rem", background: "#FFF", padding: "2rem 1.25rem"}}>
          <div style={{ padding: '10px' }}>
            {firstHalf.map(([key, value]) => (
              <Info label={key as string} value={value as string} key={key as string} />
            ))}
          </div>
        </Box>
        <Box sx={{borderRadius: "0.9375rem", background: "#FFF", padding: "2rem 1.25rem", marginTop: "1.25rem", marginBottom: "1.88rem"}} >
          <div style={{ padding: '10px' }}>
            {secondHalf.map(([key, value]) => (
              <Info label={key as string} value={value as string} key={key as string} />
            ))}
          </div>
        </Box>
      </div>
    </>
  );
};

export default AssetInfo;
