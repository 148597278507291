import { Asset } from "../../types/types";
import Arrow from "../../assets/icon_arrow_down.svg";

interface AssetSideBarMinifiedProps {
  asset?: Asset;
  showAirQuality: boolean;
  showArrow: boolean;
  open: boolean;
  setOpen: (open: boolean) => void;
}

export function AssetsSidebarMinified({ open, asset, showAirQuality, showArrow, setOpen }: AssetSideBarMinifiedProps) {
  const priceChange = 0;
  const priceBoxColor = priceChange > 0 ? 'bg-[var(--warm-orange)]' : 'bg-[var(--primary-dodger-blue)] ';
  const handleModalOpen = () => {
      setOpen(!open);
  };

  return (
    <>
    <div className={`md:hidden mt-16 bg-[var(--primary-dodger-blue)] cursor-default md:w-[17.25rem] md:h-[5rem] px-[1.31rem] py-[1.44rem] rounded-[0.625rem] md:ml-[2.56rem] mb-[0.62rem] rounded-[0.625rem]`}>
      <div className={`flex justify-between items-center`}>
        <p className="text-base text-white">{asset?.name}</p>
        <div className="flex items-center">
          <div className={`rounded-[35px] bg-white text-[var(--text-color-primary)] flex items-center md:justify-center md:rounded-[2.1875rem] py-[13px] grid place-content-center   w-[72px] md:h-[2.52rem] ${priceBoxColor}`}>
            <p className="text-center text-base w-full md:w-13 ">
              {showAirQuality ? asset?.pm25 : '$' + asset?.price}
            </p>
          </div>
          {showArrow && (
          <div className={`md:hidden`}>
             <img src={Arrow} alt="arrow" className="w-[1.75rem] h-[1.75rem] ml-[0.5rem]" onClick={handleModalOpen}/>
           </div>
           )
          }
        </div>
      </div>
    </div>
    </>
  );
}

