import { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import { useAuth0 } from '@auth0/auth0-react';
import iconArrow from '../../assets/icon_arrow_right.svg';
import menu from '../../assets/menu_icon.svg';
import close from '../../assets/close_icon.svg';
import { createUser } from '../../api/api';

export const Header = ({ assetId = 1, hiddenNav = false }) => {
  const location = useLocation();
  const { isLoading } = useAuth0();
  const [toggle, setToggle] = useState(false);
  const [active, setActive] = useState("Market"); // Default active page title
  const navLinks = [
    {
      id: 1,
      title: "Market",
      path: "/",
      pathKey: `markets`,
    },
    {
      id: 2,
      title: "Assets",
      path: `/assets/${assetId}`,
      pathKey: `assets`,
    },
    {
      id: 3,
      title: "Air Quality",
      path: `/air-quality/${assetId}`,
      pathKey: `air-quality`,
    },
    {
      id: 4,
      title: "Portfolio",
      path: `/portfolio`,
      pathKey: `portfolio`,
    },
    {
      id: 5,
      title: "Leaderboard",
      path: `/leaderboard`,
      pathKey: `leaderboard`,
    }
  ];

  const { loginWithRedirect, logout, isAuthenticated, getIdTokenClaims } = useAuth0();
  const [error, setError] = useState<string | null>(null);


  useEffect(() => {
    const sendTokenToBackend = async () => {
      try {
        if (isAuthenticated) {
          const tokenClaims = await getIdTokenClaims();
          if (tokenClaims) {
            const { name, email, sub } = tokenClaims;
            await createUser(name ?? '', email ?? '', sub ?? '');
          }
        }
      } catch (error) {
        console.error("Axios error happened", error);
        setError("Error logging in");
      }
    };


    // Set active based on current pathname on page reload
    const currentPathname = location.pathname;
    const matchingNavLink = navLinks.find(nav => currentPathname.startsWith(`/${nav.pathKey}`));

    if (matchingNavLink) {
      setActive(matchingNavLink.title);
    } else{
      setActive(navLinks[0].title)
    }
    sendTokenToBackend();
  }, [isAuthenticated, location.pathname, navLinks]);
  
  

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    logout({ logoutParams: { returnTo: window.location.origin } });
  };

  const getEndpoint =(path:string)=>{
    if(path === 'assets'){
      return `${window.location.origin}/assets/1`
    }else if(path === 'air-quality'){
      return `${window.location.origin}/air-quality/1`
    }else{
      return window.location.href
    }
  }
  
let currentpath = window.location.pathname
const formattedPath = currentpath.replace(/[\d/]/g, '');
let endpoint = getEndpoint(formattedPath)
const loginParams ={authorizationParams: { redirect_uri:endpoint}}

  const authBtn = () => {
    if (isAuthenticated) {
      return (
        <div className={`hidden md:flex md:ml-[3.18rem]`}>
          <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
            <path d="M2.77778 25C2.01389 25 1.35972 24.7278 0.81528 24.1833C0.270835 23.6389 -0.000923568 22.9852 2.35805e-06 22.2222V2.77778C2.35805e-06 2.01389 0.272225 1.35972 0.816669 0.81528C1.36111 0.270835 2.01482 -0.000923568 2.77778 2.35805e-06H12.5V2.77778H2.77778V22.2222H12.5V25H2.77778ZM18.0556 19.4444L16.1458 17.4306L19.6875 13.8889H8.33333V11.1111H19.6875L16.1458 7.56945L18.0556 5.55556L25 12.5L18.0556 19.4444Z" fill="#5E83FF" />
          </svg>
          <button className="ml-2 invisible md:visible text-[var(--primary-dodger-blue)]" onClick={() => handleLogout()}>
            Logout
          </button>
        </div>
      );
    } else {
      return (
        <div className="hidden md:flex md:ml-[3.18rem]">
          <button className='hidden md:flex text-sm md:text-base font-semibold' onClick={() => loginWithRedirect(loginParams)}>Login</button>
        </div>
      );
    }
  };

  const handleNavItemClicked = (navTitle: string) => {
    setActive(navTitle);
    setToggle(false);
  };



  if (isLoading) {
    return null;
  }
  return (
    <>
      <nav className="flex flex-row items-center justify-between md:mx-auto md:px-12 md:py-[1rem] bg-white sticky top-0 z-50">
        <div className="logo md:pl-[0.5rem] pl-[1.25rem]">
          <Link to="/" className="text-2xl md:text-[1.85rem] font-light">
            PBX
          </Link>
        </div>
        {/* Desktop Navigation */}
        {!hiddenNav && (
          <div className="">
            <ul className="hidden md:justify-start md:items-center md:flex md:gap-8 lg:gap-10 ">
              {navLinks.map((nav, index) => (
                <Link to={nav.path}
                  key={index}
                  className={`text-base md:text-base hover:underline hover:underline-offset-[1.5rem] decoration-4	decoration-[#5d83ff] hover:text-[#5d83ff] font-semibold ${active === nav.title ? "underline-offset-[1.5rem] text-[#5d83ff] underline" : ""
                    }`}
                  onClick={() => handleNavItemClicked(nav.title)}
                >
                  {nav.title}
                </Link>
              ))}
            </ul>
          </div>
        )}
        {/* Mobile Navigation */}
        {!hiddenNav && (
          <div className="md:hidden flex items-center pr-[1.25rem] py-[1.56rem]">
            <img
              src={toggle ? close : menu}
              alt="menu"
              className="z-50"
              onClick={() => setToggle(!toggle)}
            />

            <div
              className={`${!toggle ? "hidden" : "flex"} mt-7 flex-col gap-[7.5rem] absolute top-10 right-0 h-[100vh] w-full bg-white`}
            >
              <ul className="flex flex-col gap-[4.5rem] mt-8 z-40 bg-white p-4 text-left">
                {navLinks.map((nav, index) => (
                  <Link to={nav.path}
                    key={nav.id}
                    className={`flex justify-between font-semibold cursor-pointer border-b-[0.125rem] border-solid border-[#E5E7EB] text-[16px] w-full ${active === nav.title ? "active text-[var(--primary-dodger-blue)]" : ""
                      } ${index === navLinks.length - 1 ? "mb-2" : "mb-0"}`}
                      onClick={() => handleNavItemClicked(nav.title)}
                  >
                    <div>
                      {nav.title}
                    </div>
                    <div>
                      <img
                        src={iconArrow}
                        alt="Icon Arrow"
                        className="z-50"
                        onClick={() => handleNavItemClicked(nav.title)}
                      />
                    </div>
                  </Link>
                ))}
              </ul>
              {isAuthenticated ? 
              <div className={`flex justify-center mt-[16rem] `}>
                <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
                  <path d="M2.77778 25C2.01389 25 1.35972 24.7278 0.81528 24.1833C0.270835 23.6389 -0.000923568 22.9852 2.35805e-06 22.2222V2.77778C2.35805e-06 2.01389 0.272225 1.35972 0.816669 0.81528C1.36111 0.270835 2.01482 -0.000923568 2.77778 2.35805e-06H12.5V2.77778H2.77778V22.2222H12.5V25H2.77778ZM18.0556 19.4444L16.1458 17.4306L19.6875 13.8889H8.33333V11.1111H19.6875L16.1458 7.56945L18.0556 5.55556L25 12.5L18.0556 19.4444Z" fill="#5E83FF" />
                </svg>
                <button className="ml-2 text-[#5d83ff] font-semibold" onClick={() => handleLogout()}>
                  Logout
                </button>
              </div>
              :
              <div className={`flex justify-center items-center`}>
                <button className='text-sm md:text-base font-semibold' onClick={() => loginWithRedirect(loginParams)}>Login</button>
              </div>
              }
            </div>
          </div>
        )}
        {authBtn()}
      </nav>
    </>
  );
};
