import { useAuth0 } from '@auth0/auth0-react'
import React from 'react'

const BlankState = () => {

  const { loginWithRedirect } = useAuth0()
  const signupParams ={authorizationParams: { screen_hint: "signup",redirect_uri:`${window.location.origin}/portfolio` }}
  const loginParams ={authorizationParams: { redirect_uri:`${window.location.origin}/portfolio` }}

  return (
    <div className='p-[20px] md:px-[40px] md:py-[50px] w-full '>
       <div className="rounded-[15px] w-full py-[36px] px-[38px] md:py-[175px]  md:px-[70px] min-[1080px]:px-[100px] min-[1510px]:px-[320px] bg-[white] flex flex-col  justify-center items-center bg-[url('/src/assets/mobile_layer_bg.svg')] md:bg-[url('/src/assets/bg_layer.svg')] bg-no-repeat bg-center bg-origin-content ">
        <h2 className=" text-[35px] min-[380px]:text-[40px] md:text-[60px] min-[786px]:text-[70px] min-[1020px]:text-[100px] text-[var(--text-color-primary)] font-black text-center  ">Build Your Portfolio</h2>
        <p className='text-[14px] min-[380px]:text-[18px] md:text-[30px] text-[var(--text-color-primary)] font-normal my-[35px] md:my-[70px] w-[90%] min-[1080px]:w-[60%]  text-center'>Sign-up and get <span className='text-[var(--primary-dodger-blue)] font-bold'>$10M</span> in play money to trade in the market.</p>
        <div onClick={() => loginWithRedirect(signupParams)} className="bg-[var(--primary-dodger-blue)] w-[118px]  md:w-[263px] py-[12px] min-[380px]:py-[16px] md:py-[13px] flex justify-center text-[white]  text-[12px] min-[380px]:text-[15px] md:text-[20px] font-white font-bold rounded-[10px]  md:rounded-[17px] cursor-pointer">Sign Up</div>
        <div  className="w-[118px] md:w-[263px] py-[12px] min-[380px]py-[16px] md:py-[13px] flex justify-center "> <p onClick={()=>loginWithRedirect(loginParams)} className="cursor-pointer text-[var(--primary-dodger-blue)] text-[12px] min-[380px]:text-[15px] md:text-[20px] font-white md:font-bold cursor-pointer">Login</p> </div>
      </div>
    </div>
  )
}

export default BlankState