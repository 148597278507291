import { useCallback } from "react";
import { TradePresentationItem } from "../../types/types";

export const useUpdateVisibleItems = (displayItems: TradePresentationItem[], MAX_VISIBLE_ITEMS: number) => {
  const updateVisibleItems = useCallback((prevItems: TradePresentationItem[]): TradePresentationItem[] => {
    const newIndex = getNextIndex(prevItems, displayItems);
    const newItem = createNewItem(newIndex, displayItems);
    const updatedItems = [newItem, ...prevItems];

    if (updatedItems.length > MAX_VISIBLE_ITEMS) {
      markLastItemForExit(updatedItems, MAX_VISIBLE_ITEMS);
    }

    return updatedItems.slice(0, MAX_VISIBLE_ITEMS + 1); // Keep the extra for exit
  }, [displayItems, MAX_VISIBLE_ITEMS]);

  return updateVisibleItems;
};

const createNewItem = (newIndex: number, displayItems: TradePresentationItem[]): TradePresentationItem => {
  const newItemData = displayItems[newIndex];
  return {
    ...newItemData,
    uniqueKey: `${newItemData.trade_type}-${newItemData.id}-${Date.now()}`,
    exit: false
  };
};

const markLastItemForExit = (items: TradePresentationItem[], MAX_VISIBLE_ITEMS: number): void => {
  const exitingItem = items[MAX_VISIBLE_ITEMS];
  if (exitingItem) {
    items[MAX_VISIBLE_ITEMS] = { ...exitingItem, exit: true };
  }
};

const getNextIndex = (currentVisible: TradePresentationItem[], displayItems: TradePresentationItem[]): number => {
  if (currentVisible.length === 0) return 0;
  const lastItem = currentVisible[0];
  const lastIndex = displayItems.findIndex(
    (item: TradePresentationItem) => item.id === lastItem.id
  );
  return (lastIndex + 1) % displayItems.length;
};