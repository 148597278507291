import React, { ReactElement, useEffect, useState } from 'react'
import { motion } from 'framer-motion';
import { useDebouncedResize } from '../../helpers/Debounce';

interface ContainerProps {
  children:ReactElement
}

const ChartContainer = ({ children }: ContainerProps ) => {
  const [refreshing, setRefreshing] = useState(false)
  const {width} = useDebouncedResize(1)

  useEffect(()=>{
    setRefreshing(true)
    setTimeout(()=>{
      setRefreshing(false)
    },500)
  },[width])

  if(refreshing) return <div></div>
  return (
    <motion.div
    className=""
    initial={{ opacity: 0 }}
    animate={{ opacity: 1 }}
    exit={{ opacity: 0 }}
    transition={{ delay: 0, duration: 0.3 }}
  >
    {children}
    </motion.div>
  )
}

export default ChartContainer