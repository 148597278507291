import Tooltip from '../tooltip/Tooltip'
import {  ReactElement } from 'react'

interface Props {
  tooltipMessage:string
  icon:ReactElement
}

const IconTooltipWrapper = ({ tooltipMessage, icon }: Props) => {
  return (
    <Tooltip sx={{fontSize:{xs:10, sm:12,  md:16}}} title={tooltipMessage} placement='top' arrow>
        {icon}
    </Tooltip>
  )
}

export default IconTooltipWrapper;