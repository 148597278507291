import React from 'react'
import { PortfolioUserData } from '../../../types/types'
interface UserInfoProps {
  user_details: PortfolioUserData
}

const UserGreetingCard =({user_details}:UserInfoProps) => {
  const name = user_details.name.split(" ")[0]
  return (
    <div className='w-full rounded-[15px] bg-white py-[33px] px-[44px] md:py-[48px] md:px-[60px] mb-[24px]'>
      <p className=" text-[20px] md:text-[20px] font-semibold text-[var(--text-color-primary)] mb-[10px]">{user_details?.isUsersPortfolio ? `Hey, ${name}`: 'Viewing'} </p>
      <p className="max-[380px]:leading-[30px] text-[30px] md:text-[55px] font-black text-[var(--text-color-primary)]">{user_details?.isUsersPortfolio ? 'GOOD MORNING': `${name}'s Portfolio` }</p>
    </div>
  )
}

export default UserGreetingCard