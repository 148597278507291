import { useState, useEffect} from 'react';
import { useNavigate } from 'react-router-dom';
import { Template } from './template';
import { switchToBaseNetwork } from '../../web3/switchNetwork';
import { isBaseNetwork as getIsBaseNetwork } from '../../web3/isNetwork';

export const Tokens = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(false);
  const [error, setError] = useState<string | null>(null); // Updated to accept string or null
  const [isBaseNetwork, setIsBaseNetwork] = useState<boolean>(false);

  const header = `Switch to Base Network`;
  const primaryButton = 'Continue';

  const handleContinue = async () => {
    setLoading(true);
    setError(null);

    if (!window.ethereum) {
      setError('MetaMask is not installed. Please install it to proceed.');
      setLoading(false);
      return;
    }

    try {
      const switched = await switchToBaseNetwork();
      setIsBaseNetwork(switched);
      if (switched) {
        navigate('/onboarding/community');
      } else {
        setError('Failed to switch to the Base network. Please try again.');
      }
    } catch (err) {
      console.error('Error during network switch:', err);
      setError('An unexpected error occurred. Please try again.');
    }

    setLoading(false);
  };

  useEffect(() => {
    const checkNetwork = async () => {
      const isBase = await getIsBaseNetwork();
      setIsBaseNetwork(isBase);
    };
    checkNetwork();
  }, []);

  return (
    <Template
      header={header}
      primaryButton={loading ? 'Processing...' : primaryButton}
      primaryButtonDisabled={loading}
      onPrimaryButtonClick={handleContinue}
      errorMessage={error || undefined}
    >
      <div>{isBaseNetwork ? '✅' : '🟥'} Add Base Network</div>
      <div>{isBaseNetwork ? '✅' : '🟥'} Switch to Base Network</div>
      <div>Add PBX Reward Token</div>
      <div>Receive 50 Reward Tokens</div>
    </Template>
  );
};
