import { Dialog } from '@headlessui/react'

interface BuySellSwitchProps {
  isBuying: boolean;
  handleSwitchBuySell: () => void;
  assetName: string;
}

export const BuySellSwitch = ({ isBuying, handleSwitchBuySell, assetName }: BuySellSwitchProps) => {

  return (
    <div className="flex flex-row justify-between mb-[15px]">
      <Dialog.Title className="text-[18px] font-bold md:text-[25px] text-[#424242] ">
        {assetName}
      </Dialog.Title>
      <Toggle isBuying={isBuying} handleSwitchBuySell={handleSwitchBuySell} />
    </div>
  )
}

interface ToggleProps {
  isBuying: boolean;
  handleSwitchBuySell: () => void;
}

const Toggle = ({isBuying, handleSwitchBuySell}: ToggleProps) => {
  return (
    <div className="flex flex-row justify-between w-[100px] h-[37px] overflow-hidden">
      <h3 className={`cursor-pointer text-[12px] md:text-[12px] w-1/2 grid place-content-center rounded-tl-[10px] rounded-bl-[10px] border-[1px] ${isBuying ? 'text-white bg-[var(--primary-dodger-blue)] border-[var(--primary-dodger-blue)]' : 'text-[var(--text-color-primary)] border-[#ccc]'}`} onClick={handleSwitchBuySell}>
        Buy
      </h3>
      <h3 className={`cursor-pointer text-[12px] md:text-[12px] w-1/2 grid place-content-center border-[1px] rounded-tr-[10px] rounded-br-[10px]   ${!isBuying ? 'text-white bg-[var(--primary-dodger-blue)] border-[var(--primary-dodger-blue)]' : 'text-[var(--text-color-primary)] border-[#ccc]' }`} onClick={handleSwitchBuySell}>
        Sell
      </h3>
    </div>
  )
}
