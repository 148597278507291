import { useNavigate } from "react-router-dom";
import { TradePresentationItem } from "../../types/types";
import { getUserName } from '../RecentTrades/supportingComponents';


export const TableRow: React.FC<{ 
  item: TradePresentationItem; 
  index: number; 
  isGainers: boolean; 
}> = ({ item, index, isGainers }) => {
  const navigate = useNavigate();

  const handleClick = () => {
    navigate(`/portfolio/${item.user_id}`);
  };

  return (
    <div
      className="flex px-4 py-2 text-sm hover:bg-gray-100 cursor-pointer"
      onClick={handleClick}
    >
      <div className="w-1/8 text-left pl-1 text-gray-500 text-xs">
        #{index}
      </div>
      <div className="w-1/4 text-right pl-2 text-gray-500">
        {getUserName(item.user_id)}
      </div>
      <div 
        className={`w-1/3 text-right pr-2 pl-2 ${
          isGainers ? "text-[var(--turquoise-green)]" : "text-[var(--warm-orange)]"
        }`}
      >
        ${isGainers ? formatNumber(item.absolute_pnl) : formatNumber(Math.abs(item.absolute_pnl))}
      </div>
      <div 
        className={`w-1/3 text-right pr-2 ${
          isGainers ? "text-[var(--turquoise-green)]" : "text-[var(--warm-orange)]"
        }`}
      >
        {isGainers ? formatNumber(item.percentage_pnl) : formatNumber(Math.abs(item.percentage_pnl))}%
      </div>
    </div>
  );
};

  // Number formatter with commas
  const formatNumber = (num: number) => {
    return Math.abs(num).toLocaleString();
  };