import { useEffect, useState } from 'react'
import ErrorMessage from '../error/ErrorMessage'
import { getLeaderboardData } from '../../api/api'
import { WaveDots } from '../accessory/loading'
import { LeaderboardData } from '../../types/types'
import LeaderboardTable from './LeaderboardTable'

export const Leaderboard = () => {
  const [responseData, setResponseData] = useState<LeaderboardData | null>(null)
  const [isLoading, setIsLoading] = useState<Boolean>(false)
  const [error, setError] = useState<string | null>(null)

  const fetchData =async()=>{
    setIsLoading(true)
    try {
      const response = await getLeaderboardData()
      const {leaderboard} = response
      setResponseData(leaderboard)
      setIsLoading(false)
    } catch (error: any) {
      setError("Something went wrong! Failed to fetch Leaderboard page data");
      setIsLoading(false)
    }
  }


  useEffect(()=>{
    fetchData()
  },[])

  if (!responseData || isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <WaveDots />
      </div>
    );
  }

  if (error) {
    return <ErrorMessage message={error} />
  }

  return (
      <div className='p-[20px] md:py-[40px] md:pt-[2.19rem] md:px-[50px]'>
        <div className="w-full bg-white py-[32px] px-[29px] md:py-[44px] md:px-[50px] rounded-[15px] mb-[10px] md:mb-[20px]">
          <h2 className='text-[25px] md:text-[55px] font-bold text-[var(--text-color-primary)] mb-[10px] md:mb-[14px]'>Leaderboard</h2>
          <p className="text-[14px] md:text-[18px] text-[var(--text-color-primary)] font-semibold">How you stack up against the community.</p> 
        </div>
        <div>
          <LeaderboardTable tableColumnsData={responseData.columns} tableValuesData={responseData.data} />
        </div>
      </div>
  )
}


