import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { motion, AnimatePresence } from "framer-motion";
import { getTransactionQueueData } from "../../api/api";
import { TradePresentationItem } from "../../types/types";
import { useUpdateVisibleItems } from "./useUpdateVisibleItem";
import { TradeCard } from "./supportingComponents";
import { scheduleNextUpdate, clearScheduledUpdate } from "./supportingComponents";

export const RecentTrades = ({assetId, upperBoundSpeed, lowerBoundSpeed}: {assetId?: string, upperBoundSpeed?: number, lowerBoundSpeed?: number}) => {
  const [tradePresentationItems, setTradePresentationItems] = useState<TradePresentationItem[]>([]);
  const [visibleItems, setVisibleItems] = useState<TradePresentationItem[]>([]);

  const MAX_VISIBLE_ITEMS = 5;
  const updateVisibleItems = useUpdateVisibleItems(tradePresentationItems, MAX_VISIBLE_ITEMS);
  const navigate = useNavigate();

  useEffect(() => {
    getTransactionQueueData(assetId)
      .then((data) => setTradePresentationItems(data))
      .catch((error) => {
        console.error("Error fetching transaction data:", error);
      });
  }, [assetId]);

  useEffect(() => {
    if (tradePresentationItems.length === 0) return;
    const handleUpdateVisible = () => {
      setVisibleItems((prevItems) => updateVisibleItems(prevItems));
      setTimeout(() => {
        setVisibleItems((prevItems) => prevItems.filter((item) => !item.exit));
      }, 500); // Match exit animation duration
      scheduleNextUpdate(handleUpdateVisible, upperBoundSpeed, lowerBoundSpeed);
    };
    scheduleNextUpdate(handleUpdateVisible, upperBoundSpeed, lowerBoundSpeed);
    return () => {
      clearScheduledUpdate();
    };
  }, [tradePresentationItems]);


  if (tradePresentationItems.length === 0) {
    return <div className="p-4 min-w-[360px]"></div>;
  }

  return (
    <>
      <div className="flex flex-col overflow-hidden h-[144px] min-w-[360px] rounded-2xl font-semibold">
        <AnimatePresence>
          {visibleItems.map((item) => (
            <motion.div
              key={item.uniqueKey}
              initial={{ y: -50, opacity: 1 }}
              animate={{ y: 0, opacity: 1 }}
              exit={{ y: 50, opacity: 0 }}
              transition={{ duration: 0.5, ease: "easeInOut" }}
              layout
              className={`flex flex-row justify-center items-center rounded-lg font-semibold
                mb-1`}
              onClick={() => navigate(`/portfolio/${item.user_id}`)}
            >
                <TradeCard trade={item}/>
            </motion.div>
          ))}
        </AnimatePresence>
      </div>
    </>
  );
};
