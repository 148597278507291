import { ModalLayer } from './ModalLayer'
import { useAuth0 } from '@auth0/auth0-react'
import Curve from '../../../assets/shape.svg'
interface Props{
  show : boolean
  onClose :() => void
}

const PromptModal = ({ show, onClose }: Props) => {
  const { loginWithRedirect } = useAuth0();

  const signupParams ={authorizationParams: { screen_hint: "signup",redirect_uri:`${window.location.origin}/assets/1` }}
  const loginParams ={authorizationParams: { redirect_uri:`${window.location.origin}/assets/1` }}

  return (
   <ModalLayer show={show} onClose={onClose} >
     <div className="overflow-hidden relative max-w-[393px] w-[90%]  md:max-w-[890px] md:w-[80%] md:min-w-[600px] py-[141px] px-[46px] md:py-[130px] md:px-[150px] min-[1080px]:py-[166px] min-[1080px]:px-[179px] bg-[var(--primary-dodger-blue)] mx-auto rounded-[30px] bg-[url('/src/assets/bg_layer_white.svg')] bg-no-repeat bg-left-bottom bg-[length:304px_304px] md:bg-[length:549px_549px] mt-[15vh] md:mt-[8vh] ">
      <div className='absolute w-[220px] h-[216px] md:w-[400px] md:h-[450px] top-[-100px] right-[-100px] md:top-[-75px] md:right-[-300px] '>
          <img src={Curve} className='w-full h-full block' alt='curve' />
      </div>
        <h2 className="text-[30px] md:text-[39px] min-[828px]:text-[45px] min-[1080px]:text-[60px] font-black text-white text-center">Build Your Portfolio</h2>
        <p className="text-[15px] md:text-[23px] min-[828px]:text-[26px] min-[1080px]:text-[30px] font-normal text-white text-center my-[45px] md:my-[55px]">Sign-up to get <span className='font-bold'>$10M</span> in 'play' money to trade on the market.</p>
        <div className="w-[263px] py-[13px] rounded-[17px] bg-white flex justify-center item-center cursor-pointer mx-auto" onClick={() => loginWithRedirect(signupParams)}>
          <p className="text-[var(--primary-dodger-blue)] font-bold text-[20px]">Sign Up</p>
        </div>
        <div className="w-[263px] py-[13px] rounded-[17px] flex justify-center item-center mx-auto">
          <p className="text-white font-bold text-[20px] cursor-pointer" onClick={()=>loginWithRedirect(loginParams)} >Login</p>
        </div>
     </div>
   </ModalLayer>
  )
}

export default PromptModal