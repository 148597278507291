import React, { useState } from 'react'
import { Cell, Pie, PieChart as BasicPieChart, ResponsiveContainer } from 'recharts'
import { AssetsHoldingsChartData } from '../../types/types';

interface ChartProps {
  data:AssetsHoldingsChartData[]
}
const PieChart = ({data}:ChartProps) => {

  const [activeIndex,setActiveIndex] = useState<number|null>(null)

  const convertValueStringToNumber = (data:AssetsHoldingsChartData[]) => {
    const convertedData = data.map(item => ({
      ...item,
      value: parseFloat(item.percentage)
    }));
    return convertedData;
  };
  const updatedData = convertValueStringToNumber(data)
  const RADIAN = Math.PI / 180;

  const renderCustomizedLabel = (props:any) => {
    const { cx, cy, midAngle, innerRadius,percentage, outerRadius, payload, index } = props
    const radius = innerRadius + (outerRadius - innerRadius) * 1.1;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const numericValue = parseFloat(percentage);
    if(activeIndex === index){
      return   <text x={x} y={y} className='fill-[--text-color-primary] text-[10px] min-[360px]:text-[11px] min-[910px]:text-[14px] font-semibold' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
      {payload.name}
    </text>
    }else if(numericValue >= 5.00){
      return (
        <text x={x} y={y} className='fill-[--text-color-primary] text-[10px] min-[360px]:text-[11px] min-[910px]:text-[14px] font-semibold' textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central">
          {percentage}%
        </text>
      );
    }
    return
  };

  return (
    <ResponsiveContainer width={'100%'} height={'100%'}>
    <BasicPieChart >
        <Pie
            data={updatedData}
            cx="50%"
            cy="50%"
            innerRadius={60}
            fill="#8884d8"
            dataKey="value"
            label={renderCustomizedLabel}
            labelLine={false}
            onMouseEnter={(_,index)=>setActiveIndex(index)}
            onMouseLeave={()=>setActiveIndex(null)}
          >
            {updatedData.map((entry, index) => (
              <Cell className='outline-0' key={`cell-${index}`} fill={entry.color} />
            ))}
          </Pie>
    </BasicPieChart>
    </ResponsiveContainer>
  )
}

export default PieChart
