import { useEffect, useState } from 'react';
import { useAuth0 } from '@auth0/auth0-react';
import { createUser} from '../../api/api'

export const Login = () => {
  const { loginWithRedirect, logout, isAuthenticated, getIdTokenClaims } = useAuth0();
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const sendTokenToBackend = async () => {
      try {
        if (isAuthenticated) {
          const tokenClaims = await getIdTokenClaims();
          if (tokenClaims) {
            const { name, email, sub } = tokenClaims;
            await createUser(name ?? '', email ?? '', sub ?? '');
          }
        }
      } catch (error) {
        console.error("Axios error happened", error);
        setError("Error logging in");
      }
    };
    sendTokenToBackend();
  }, [isAuthenticated]);

  const handleLogout = () => {
    sessionStorage.removeItem('token');
    logout({ logoutParams: { returnTo: window.location.origin } })
  }
  if (error) {
    return (
      <div className="flex justify-center items-center h-screen">
        <span className="text-red-500">{error}</span>
      </div>
    );
  }
  if (isAuthenticated) {
    return (
      // Clear session token after logout
      <div className="flex ">
        <svg className='bg-[(var(--primary-dodger-blue))]' xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
          <path d="M2.77778 25C2.01389 25 1.35972 24.7278 0.81528 24.1833C0.270835 23.6389 -0.000923568 22.9852 2.35805e-06 22.2222V2.77778C2.35805e-06 2.01389 0.272225 1.35972 0.816669 0.81528C1.36111 0.270835 2.01482 -0.000923568 2.77778 2.35805e-06H12.5V2.77778H2.77778V22.2222H12.5V25H2.77778ZM18.0556 19.4444L16.1458 17.4306L19.6875 13.8889H8.33333V11.1111H19.6875L16.1458 7.56945L18.0556 5.55556L25 12.5L18.0556 19.4444Z" />
        </svg>
        <button className="ml-2 invisible md:visible text-[var(--primary-dodger-blue)]" onClick={() => handleLogout()}>
        Logout
      </button>
      </div>
    );
  } else {
    return (
      <div>
        <button className='text-sm md:text-base font-semibold' onClick={() => loginWithRedirect()}>Login</button>
      </div>
    );
  }
};
