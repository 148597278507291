import AssetsTable from '../assetsTable/AssetsTable';
import MarketOverview from './MarketOverview';
import { RecentTrades } from '../RecentTrades/RecentTrades';

function Home() {
  return (
    <>
      <div className='w-full'>
        <div className='flex flex-col justify-between min-[980px]:flex-row gap-4 md:gap-8 md:py-[2.19rem] items-center w-full md:px-12 px-[20px]'>
          <div className="md:mt-0 w-full max-md:mt-[20px] md:basis-2/3">
            <Subtitle />
          </div>
          <div className="w-full mb-[1rem] md:mb-0 md:basis-1/3">
            <RecentTrades />
          </div>
        </div>
        <MarketOverview />
        <div  className='w-full md:px-12 px-[20px]'>
          <AssetsTable />
        </div>
      </div>
    </>
  );
}

const Subtitle = () => {
  return (
    <div className="grid grid-row p-[1.5rem] justify-center md:justify-start md:bg-white rounded-[0.9375rem]  md:w-full bg-[#FFF] md:py-[25px] md:pl-[58px] md:h-[144px] ">
     <div className="max-[768px]:text-[1rem] md:text-[2.1875rem]   md:leading-9 cursor-default font-bold mb-[0.5rem] max-w-[358px] flex items-center justify-center h-full">
    Air Quality Predictions Market.
</div>
      {/* <div className=" text-xs  cursor-pointer">
        <Link to="/market-info">
          <span>Learn how the market works </span>
          <span className="text-blue-500 hover:text-blue-800">here</span>
        </Link>.
      </div> */}
    </div>
  )
}

export default Home;