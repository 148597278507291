export const getColorBasedOnAssetId = (assetId: number): string => {
  const colorMap: { [key: number]: string } = {
    1: "#E63946",
    2: "#457B9D",
    3: "#2A9D8F",
    4: "#8338EC",
    5: "#FFA07A",
    6: "#F4A261",
    7: "#2B9348",
    8: "#4A90E2",
    9: "#F94144",
    10: "#8D99AE"
  };
  return colorMap[assetId] || "#000000";
};

export const formatNumber = (num: number, isCurrency: boolean = true): string => {
  const currencyAppend = isCurrency ? '$' : ''
  if (typeof num !== 'number') return "N/A";
  if (num >= 1e12) return `${currencyAppend}${(num / 1e12).toFixed(1)}T`;
  if (num >= 1e9) return `${currencyAppend}${(num / 1e9).toFixed(1)}B`;
  if (num >= 1e6) return `${currencyAppend}${(num / 1e6).toFixed(1)}M`;
  if (num >= 1e3) return `${currencyAppend}${(num / 1e3).toFixed(1)}K`;
  return `${currencyAppend}${num.toFixed(2)}`;
};
