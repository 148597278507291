import { formatAmount, formatNumberTo2Decimal } from './utils'
import LoadingIndicator from './loadingitem'
import { modalInputClass, modalSubTextClass } from './consts'
import { PriceChangePrediction } from './PricePrediction'
interface AssetFormProps {
  currentPrice: number
  cancelButtonRef: any
  shares: number
  formattedTotalPrice: string
  totalPrice: number
  balance: number
  currentOwnedShares: number
  error: string
  successMessage: string
  isLoading: boolean
  handleSharesChange: (event: React.ChangeEvent<HTMLInputElement>) => void
  handleAction: () => void
  handleCloseModal: () => void
  isBuying: boolean
  assetId?: string
}

const AssetForm = ({
  currentPrice,
  cancelButtonRef,
  shares,
  formattedTotalPrice,
  totalPrice,
  balance,
  currentOwnedShares,
  error,
  successMessage,
  isLoading,
  handleSharesChange,
  handleAction,
  handleCloseModal,
  isBuying,
  assetId
}: AssetFormProps) => {
  return (
    <>
      <div className="flex flex-row justify-between mt-10 items-center">
        <div className={`${modalSubTextClass} w-1/2`}>Shares</div>
        <div>
          <input
            type="text"
            inputMode="numeric"
            value={shares}
            onChange={handleSharesChange}
            className={`${modalInputClass} w-[166px]`}
            pattern="[0-9]*"
          />
        </div>
      </div>
      <div className={`${border} pb-4`}>
        <div className="flex flex-row justify-between mt-8 pb-2 relative">
          <div className="text-base font-bold leading-6 text-gray-900">
            <div className={`${modalSubTextClass}`}>Market Price</div>
          </div>
          <div className={`text-right ${modalSubTextClass}`}>
            {formatAmount(currentPrice)}
          </div>
        </div>
        {assetId && <PriceChangePrediction assetId={assetId} />}
      </div>
      <div className={`${border} flex flex-row justify-between mt-8 relative`}>
        <div className={`${modalSubTextClass}`}>
          {isBuying ? 'Estimated Cost' : 'Estimated Proceeds'}
        </div>
        <div className={`text-right ${modalSubTextClass}`}>
          {formattedTotalPrice}
        </div>
      </div>
      <div className={`${border} flex flex-row justify-between mt-12 relative`}>
        <div className={`${modalSubTextClass}`}>
          {isBuying ? 'Cash Available' : 'Shares Available'}
        </div>
        <div className={`text-right ${modalSubTextClass}`}>
          {isBuying ? formatAmount(balance) : formatNumberTo2Decimal(currentOwnedShares)}
        </div>
      </div>
      <div className={`${border} flex flex-row justify-between mt-12 relative`}>
        <div className={`${modalSubTextClass}`}>
          {isBuying ? 'Shares Owned' : 'Cash Owned'}
        </div>
        <div className={`text-right ${modalSubTextClass}`}>
          {isBuying ? formatNumberTo2Decimal(currentOwnedShares) : formatAmount(balance)}
        </div>
      </div>
      <div className="grid grid-flow-col justify-center mt-6">
        {error && (
          <span className="text-base font-semibold leading-6 text-[var(--warm-orange)]">
            {error}
          </span>
        )}
      </div>
      <div className="grid grid-flow-col justify-center my-6">
        {successMessage && (
          <span className="text-base font-semibold leading-6 text-[var(--turquoise-green)]">
            {successMessage}
          </span>
        )}
      </div>
      <div className="grid grid-cols-2 gap-4 grid-flow-col justify-between justify-stretch mt-4">
        <div className="w-full">
          <button
            type="button"
            disabled={isLoading || shares === 0 || (isBuying ? totalPrice > balance : shares > currentOwnedShares)}
            className={`inline-flex justify-center w-full rounded-[8px] md:rounded-[17px]
              border border-transparent shadow-sm px-4 py-3 
              p-2 ${isLoading || shares === 0 || (isBuying ? totalPrice > balance : shares > currentOwnedShares) ? 'bg-[#ccc]' : 'bg-[var(--primary-dodger-blue)]'}
              hover:opacity-[0.8] 
              text-white text-[14px] md:text-[20px] font-bold`}
            onClick={() => handleAction()}
          >
            {isLoading && <LoadingIndicator />} {isBuying ? 'BUY NOW' : 'SELL NOW'}
          </button>
        </div>
        <div className="w-full text-center">
          <button
            type="button"
            className="inline-flex justify-center w-full rounded-[8px] md:rounded-[17px]
              border-[#ccc] border-[1px] shadow-sm px-4 py-3 bg-white text-[14px] md:text-[20px] font-bold text-[var(--text-color-primary)] focus:outline-none md:text-md"
            onClick={handleCloseModal}
            ref={cancelButtonRef}
          >
            CLOSE
          </button>
        </div>
      </div>
    </>
  )
}

const border = "border-b-[1px] border-[#ccc] pb-3"

export default AssetForm