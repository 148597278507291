import { useParams } from 'react-router-dom'
import { useEffect, useState } from 'react'
import ErrorMessage from '../error/ErrorMessage'
import { useAuth0 } from '@auth0/auth0-react'
import FinancialInfo from './FinancialInfo/FinancialInfo'
import UserGreetingCard from './UserGreetingCard/UserGreetingCard'
import AssetsHoldings from './AssetsHoldings/AssetsHoldings'
import RecentTransactions from './RecentTransations/RecentTransactions'
import { getPortFolioData } from '../../api/api'
import { WaveDots } from '../accessory/loading'
import { PortfolioAPIResponse } from '../../types/types'
import BlankState from './BlankState/BlankState'

export const Portfolio = () => {
  const { userId } = useParams<{ userId: string }>()
  const [data, setData] = useState<PortfolioAPIResponse| null>(null)
  const [isLoading, setIsLoading] = useState<Boolean>(false)
  const [error, setError] = useState<string | null>(null)
  const { isAuthenticated } = useAuth0();
  const token = sessionStorage.getItem('token')

  const fetchData =async()=>{
    setError('')
    setIsLoading(true)
    try {
      const response = await getPortFolioData(userId)
      setData(response)
      setIsLoading(false)
    } catch (error: any) {
      setError("Something went wrong! Failed to fetch Portfolio page data");
      setIsLoading(false)
    }
  }

  useEffect(()=>{
    fetchData()
  },[userId,token])

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  if(!isAuthenticated && !userId ){
    return <BlankState />
  }

  if (!data || isLoading) {
    return (
      <div className="flex justify-center items-center h-screen">
        <WaveDots />
      </div>
    );
  }

  if (error) {
    return <ErrorMessage message={error} />
  }

  return (
      <div className='p-[20px] md:px-[30px] min-[1180px]:px-[50px] md:py-[40px] md:pt-[2.19rem]'>
        <UserGreetingCard user_details={data['userDetails']} />
        <FinancialInfo data={data.portfolioData} />
        <AssetsHoldings  tableColumnsData={data.assetsHoldings.columns} tableValuesData={data.assetsHoldings.data} assetHoldingsPieChartData={data.assetsHoldingsChartData} />
        <RecentTransactions tableColumnsData={data.recentTransactions.columns} tableValuesData={data.recentTransactions.data} />
      </div>
  )
}


