import { addNetwork } from './addNetwork';
import { BASE_NETWORK_PARAMS } from './networkParams';

// Main function to switch to the Base network
export const switchToBaseNetwork = async (): Promise<boolean> => {
  return await attemptSwitch(BASE_NETWORK_PARAMS.chainId);
};

// Attempts to switch to the network, with optional retry flag
const attemptSwitch = async (chainId: string, isRetry: boolean = false): Promise<boolean> => {
  try {
    return await _switchEthereumChain(chainId, isRetry);
  } catch (error) {
    return await _handleSwitchError(error, chainId);
  }
};

// Requests switching to a specified network
const _switchEthereumChain = async (chainId: string, isRetry: boolean = false): Promise<boolean> => {
  try {
    await window.ethereum?.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId }],
    });
    console.log(isRetry ? 'Switched to Base network successfully after adding!' : 'Switched to Base network successfully!');
    return true;
  } catch (error) {
    throw error;
  }
};

// Handles specific error codes when attempting to switch networks
const _handleSwitchError = async (error: any, chainId: string): Promise<boolean> => {
  switch (error?.code) {
    case 4902:
      console.log('Base network not found. Adding now...');
      const added = await addNetwork(BASE_NETWORK_PARAMS);
      if (added) {
        return await attemptSwitch(chainId, true);
      }
      return false;

    case 4001:
      // User rejected the request
      console.error('User rejected the network switch request.');
      return false;

    default:
      console.error('Failed to switch to Base network:', error);
      return false;
  }
};
