import { useState, useEffect } from 'react';
import { getMarketOverview } from '../../api/api'
import { MarketData } from '../../types/types'
import Percent from '../accessory/Percent';
import { motion } from "framer-motion";
import { WaveDots } from '../accessory/loading';
import ErrorMessage from '../error/ErrorMessage';

const MarketOverview = () => {

  const [data, setData] = useState<MarketData | null>(null);
  const [error, setError] = useState<string | null>(null);

  const fetchMarketOverview = async () => {
    try {
      const data = await getMarketOverview();
      setData(data);
    } catch (error: any) {
      setError("Something went wrong! Failed to fetch market overview");
    }
  }

  useEffect(() => {
    fetchMarketOverview();
  }, []);

  if (error) {
    return <ErrorMessage message={error} />
  }
  if (!data ) {
    return (
      <div className="flex justify-center items-center h-screen">
        <WaveDots />
      </div>
    );
  }


  return (
    <motion.div
      initial={{ opacity: 0, y: -3 }}
      animate={{ opacity: 1, y: 0 }}
      exit={{ opacity: 0, y: 0 }}
      transition={{ duration: 0.5 }}
      className="mb-[2.19rem]"
    >
      <div className="max-[980px]:overflow-x-auto py-2 bg-white md:px-12 px-[20px]">
        <div
          className="flex flex-nowrap gap-[3rem] justify-between  items-center min-w-full cursor-default max-[980px]:mr-[80px]"
        >
          {data && Object.entries(data)
            .sort(([, a], [, b]) => a.order - b.order)
            .map(([key, value]) => (
              <div key={key} className="flex justify-between text-sm items-center" >
                <span className="whitespace-nowrap pr-1 text-[0.75rem]">{`${value.label}:`}</span>
                <span className='text-xs'>{`$${value.value}`}</span>
                <span className='text-xs'>
                  <Percent percent={value["24hr_change"] + '%'} />
                </span>
              </div>
            ))}
        </div>
        <style>
          {`
        ::-webkit-scrollbar {
          display: none;
        }
      `}
        </style>
      </div>
    </motion.div>
  );
}

export default MarketOverview;
