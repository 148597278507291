import SideBarCard from './SideBarCard';
import { motion } from 'framer-motion'
import { Asset } from '../../types/types';

interface SideBarListProps {
  assets?: Asset[];
  selectedAsset?: Asset;
  showAirQuality: boolean;
  handleSelectAsset: (selectedAsset: Asset) => void;
}

function SideBarList({ assets = [], selectedAsset, showAirQuality, handleSelectAsset }: SideBarListProps) {


  return (
    <div className="flex flex-col min-h-full text-center w-full">
      {assets.map((asset, idx) => (
        <motion.div
          key={asset.id}
          initial={{ opacity: 0 }}
          animate={{ opacity: 1 }}
          transition={{ delay: idx * 0.01, duration: 0.25 }}  // Delay based on index
          onClick={() => handleSelectAsset(asset)}
        >
          <SideBarCard asset={asset} showAirQuality={showAirQuality} isSelected={selectedAsset?.id===asset.id} />
        </motion.div>
      ))}
      {assets && assets.length > 0 &&
      <p className='text-sm pt-2 text-gray-600'>
        Latest {showAirQuality ? 'pm2.5 levels' : 'asset prices'}
      </p>}
    </div>
  );
}

export default SideBarList;
